@mixin setFlex($jc,$ai,$fd) {
  display: flex;
  justify-content: $jc;
  align-items: $ai;
  flex-direction: $fd;
}

@mixin setFont($ff,$fst,$fw,$fs,$lh) {
  font-family: $ff ,sans-serif;
  font-style: $fst;
  font-weight: $fw;
  font-size: $fs;
  line-height: $lh;
}