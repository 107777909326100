.sign-in {
  position: fixed;
  &__button {
    &.exit {
      background-color: transparent;
      position: absolute;
      top: 28px;
      left: 35px;
      z-index: 2;
    }

    &.login {
      @extend .sign-button;
      margin-top: 42px;
    }

    &.send {
      @include setFont(Roboto, normal, 500, 14px, 24px);
      color: #BD9D2D;
      background-color: transparent;
    }

    &.sign {
      @extend .sign-button;
    }

    &.save {
      @extend .sign-button;
      margin-top: 52px;
    }
  }

  &__body {
    @include setFlex(space-between, flex-start, row);
    width: 904px;
    overflow: hidden;
    position: relative;
    border-radius: 24px;
    ::-webkit-scrollbar {
      width: 0;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
      background: #FF0000;
  }
  }

  &__content {
    width: 100%;
    height: 100%;
    background: url("../../../assets/img/sign-in__bg.svg") center no-repeat;
    background-size: cover;
    position: sticky;
    overflow-x: hidden;
  }

  &__intro {
    width: 543px;
    padding: 60px 0 50px 131px;
    position: relative;
    z-index: 1;
  }

  &__title {
    @extend .sign-title;
    text-transform: capitalize;

    &.input {
      margin: 32px 0 44px 0;
    }

    &.verification, &.feedback {
      margin: 75px 0 20px 0;

      svg {
        margin-left: 10px;
      }
    }

    &.create {
      margin: 32px 0 22px 0;
    }
  }

  &__subtitle {
    @include setFont(Roboto, normal, 400, 16px, 24px);

    &, a {
      color: #FFFFFF;
    }

    &.verification {
      &.first {
        margin-bottom: 28px;
      }

      a {
        font-weight: 900;
      }
    }

    &.feedback {
      width: 428px;
      max-width: 104%;
    }

    &.create {
      &.first {
        margin-bottom: 14px;
      }
    }
  }

  &__verification {
    margin: 9px 0 8px 0;
  }

  &__description {
    color: #C1C1C1;

    &.verification, &.feedback {
      @include setFont(Roboto, normal, 400, 14px, 24px);
    }

    &.verification {
      margin: 44px 0 20px 0;
    }

    &.feedback {
      margin: 80px 0 16px 0;
    }

    &.create {
      @include setFont(Roboto, normal, 400, 16px, 24px);
      width: 431px;
      max-width: 105%;
      margin: 4px 0 16px 0;
    }
  }

  &__feedback {
    &.feedback {
      span {
        &:first-child {
          margin-right: 8px;
        }
      }
    }
  }

  &__label {
    &.input {
      margin: 0 0 48px 0;

      &:nth-child(2) {
        margin-bottom: 0;
      }
    }

    &.create {
      margin: 0 0 32px 0;

      &:nth-child(2) {
        margin-bottom: 0;
      }

      .MuiFormHelperText-root {
        margin-top: 8px;
      }
    }
  }

  &__forgot {
    width: 100%;
    text-align: right;
    margin-top: 4px;

    button {
      @include setFont(Roboto, normal, normal, 12px, 24px);
      color: #999999;
      background-color: transparent;
      transition: .3s linear;
      position: relative;
      z-index: 1;

      &:hover {
        color: #DFB729;
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }

  &__error {
    @include setFont(Roboto, normal, 500, 16px, 24px);
    color: $errorColor;

    transition: .3s linear;
    opacity: 0;
    visibility: hidden;

    &.active {
      opacity: 1;
      visibility: visible;
    }

    &.input {
      height: 24px;
      margin: 8px 0 30px 0;
    }

    &.verification {
      height: 24px;
      margin: 0 0 13px 0;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  &__title {
    @extend .sign-title;
  }

  &__footer {
    &, * {
      @include setFont(Roboto, normal, normal, 14px, 24px);
    }

    color: #FFFFFF;
    margin-top: 82px;

    button {
      color: $darkYellowColor;
      font-weight: 500;
      margin-left: 8px;
      background-color: transparent;
    }
  }

  &__code {
    display: grid !important;
    grid-template-columns: repeat(6, 52px);
    grid-column-gap: 12px;

    input {
      @include setFont(Roboto, normal, 400, 22px, 24px);
      height: 52px;
      padding-left: 17.5px;
      border-radius: 8.5px;
      background-color: #3B3B43;
      color: #FFFFFF;
      transition: .3s linear;

      &:focus {
        border-color: $lightGrayColor !important;
      }
    }
  }

  @import "media_queries/index";
}