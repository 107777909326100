@media(max-width: 699px) {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 23px;
  grid-auto-flow: dense;
  padding: 0 14px 20px 14px;
  &__item:first-child &__link:last-child, &__item:first-child &__divider {
    display: none;
  }
  &__item {
    &:nth-child(1) {
      order: 2;
    }

    &:nth-child(2) {
      order: 1;
    }
  }
  &__link {
    color: $grayColor;
  }
  &__rights {
    font-size: 13px;
    line-height: 28px;
  }
}