@media(max-width: 1025px) {
  & {
    padding-top: 129px;
  }
  &__title {
    font-size: 28px;
    line-height: 27px;
  }
  &__subtitle {
    font-size: 16px;
  }
  &__link {
    &.start {
      font-size: 16px;
      line-height: 19px;
    }

    &.demo-link {
      font-size: 15px;
      line-height: 18px;
    }
  }
  &__footer {
    grid-template-columns: repeat(2, minmax(auto, 161px));
  }
}