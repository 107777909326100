@media(max-width: 1025px) {
  &__button {
    &.exit {
      top: 21.68px;
      left: 21.68px;

      svg {
        width: 11.62px;
        height: 11.62px;
      }
    }

    &.send {
      @include setFont(Roboto, normal, 500, 14px, 24px);
    }
  }

  &__body {
    width: 700px;
    height: 494.03px;
  }

  &__intro {
    padding: 46.6px 30px 40px 100.66px;
  }

  &__logo {
    width: 146.54px;
  }

  &__title {
    font-size: 16px;
    line-height: 19px;

    &.input {
      margin: 24.78px 0;
    }

    &.verification {
      margin: 58.08px 0 15.45px 0;
    }

    &.create {
      margin: 24.78px 0 17.04px 0;
    }
  }

  &__subtitle {
    font-size: 15px;
    line-height: 20px;

    &.create {
      font-size: 15px;
      line-height: 20px;

      &.first {
        max-width: none;
        margin-bottom: 14.12px;
      }
    }
  }

  &__description {
    &.feedback {
      @include setFont(Roboto, normal, 400, 14px, 24px);
      max-width: 100%;
      width: 319px;
      margin: 100px 0 20px 0;
    }

    &.create {
      font-size: 12px;
      line-height: 17px;
      margin: 2.33px 0 14.01px 0;
    }
  }

  &__label {
    margin: 0 0 24.78px 0;

    &.double {
      grid-template-columns: repeat(2, minmax(100px, 150.22px));
      grid-column-gap: 18.58px;
    }

    &:nth-child(3) {
      margin-bottom: 34.07px;
    }

    &:nth-child(4) {
      margin-bottom: 22.14px;
    }

    &.create {
      margin: 0 0 32.52px 0;

      &:nth-child(3), &:nth-child(4) {
        margin-bottom: 44px;
      }

      & .MuiFormHelperText-root {
        @include setFont(Roboto, normal, 400, 13px, 16px);
        margin-top: 4px;
      }
    }
  }

  &__forgot {
    width: 100%;
    text-align: right;
    margin-top: 4px;

    button {
      @include setFont(Roboto, normal, normal, 12px, 24px);
      color: #999999;
      background-color: transparent;
    }
  }

  &__error {
    @include setFont(Roboto, normal, 500, 16px, 24px);
    color: $errorColor;
    margin: 8px 0 30px 0;
    transition: .3s linear;
    opacity: 0;
    visibility: hidden;

    &.create {
      height: 24px;
      margin: 19px 0;
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  &__footer {
    margin-top: 10.73px;

    &, * {
      font-size: 11px;
      line-height: 19px;
    }
  }
}