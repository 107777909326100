// PrivateBeta page 

@media(min-width: 2559px) {
    .private {
        &__subtitle {
            font-size: 36px;
            line-height: 38px;
            margin: 129px 0 106px 0;

        }

        &__form {
            margin-right: 457px;
        }

        &__label {
            grid-template-columns: repeat(2, minmax(350px, 440px));
            column-gap: 80px;
            margin-bottom: 106px;
            &:nth-of-type(4),
            &:nth-of-type(3) {
                grid-template-columns: 1fr;
            }


            &-legend{
                font-size: 21px;
                line-height: 35px;
            }
        }
    }
}