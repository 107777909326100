.private {
  &__button {
    &.join {
      background-color: transparent;
      position: absolute;
      bottom: 112px;
      right: 30px;
    }
  }

  @media(max-width: 1440px) {
    &__button {
      &.join {
        bottom: 110px;
        right: 0;

        svg {
          width: 92px;
          height: 84.64px;
        }
      }
    }
  }
  @media(max-width: 1025px) {
    &__button {
      &.join {
        bottom: 34.4px;

        svg {
          width: 80px;
          height: 73.6px;
        }
      }
    }
  }

  @media(max-width: 699px) {
    &__button {
      &.join {
        display: none;
      }
    }
  }
}