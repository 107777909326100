@media(max-width: 1155px) {
  &__process {
    grid-column-gap: 42px;
  }
  &__intro {
    &-container {
      &.anonymize {
        grid-column-gap: 50px;
      }
    }
  }
  .pairs__container.share-main .result {
    width: 355px;
    height: 260px;
}
& .videoTimeline{
  bottom: 18px;
}
}
