* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

html, body {
  min-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

body {
  background-color: $webBgColor;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

.app, #app {
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}

.wrapper {
  overflow-x: hidden;
  overflow-y: auto;

  & > * {
    max-width: 100%;
  }
}

ul {
  list-style-type: none;
}

button, input, textarea {
  border: none;
  outline: none;
}

button:not([disabled]):hover {
  cursor: pointer;
}

.modal {
  @include setFlex(center, center, column);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100vw;
  max-height: 100vh;
  background-color: rgba(11, 11, 12, 0.85);
  transition: .3s linear;
  visibility: hidden;
  opacity: 0;
  z-index: 2000;
  overflow-x: hidden;
  overflow-y: auto;

  & > * {
    max-width: calc(100% - 30px);
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.app {
  display: grid;
  grid-template-columns: 100px auto;
  position: relative;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    flex: 0 0 auto;
  }

  .main {
    flex: 1 0 auto;
  }

  .footer {
    flex: 0 0 auto;
  }
}

.main {
  &.shorted {
    max-width: calc(100% - 100px);
  }

  padding: 0 50px 0 100px;
  overflow: hidden;
}

.sign {
  &-title {
    @include setFont(Roboto, normal, 500, 22px, 24px);
    color: #FFFFFF;
    font-family: "Roboto";
    font-display: fallback;
    font-weight: normal;
    font-style: normal;
  }

  // &-title {
  //   // @include setFont(Roboto, normal, 500, 22px, 24px);
  //   font-family: Roboto;
  //   font-weight: 500;
  //   font-size: 22px;
  //   line-height: 24px;
  //   color: #FFFFFF;
  //   font-family: "Roboto";
  //   font-display: fallback;
  //   font-weight: normal;
  //   font-style: normal;
  // }

  &-button {
    @include setFont(Roboto, normal, 500, 18px, 21px);
    @include setFlex(center, center, row);
    color: #474747;
    background-color: $darkYellowColor;
    border-radius: 8px;
    padding: 10px 24px;
    text-transform: capitalize;
  }
}

@media(max-width: 1440px) {
  .main {
    padding: 0 0 0 63px;
  }
  .sign {
    &-title {
      @include setFont(Roboto, normal, 500, 20px, 24px);
    }

    &-button {
      font-size: 18px;
      line-height: 21px;
    }
  }
}

@media(max-width: 1025px) {
  .main {
    &.shorted {
      max-width: calc(100% - 70px);
    }

    padding: 0 0 0 63px;
  }
  .app {
    grid-template-columns: 1fr;

    & > .aside {
      display: none;
    }
  }
  .wrapper {
    padding: 0;

    & > .footer {
      display: none;
    }

    & > * {
      padding: 0 40px;
    }
  }
  .sign {
    &-title {
      font-size: 16.4921px;
      line-height: 19px;
    }

    &-button {
      font-size: 15px;
      line-height: 18px;
      padding: 8.25px 21.99px;
    }
  }
}

@media(max-width: 699px) {
  .wrapper {
    & > * {
      width: 100%;
      padding: 0px 24px
    }
  }
  .main {
    padding-bottom: 160px;

    &, &.shorted {
      max-width: 100%;
    }
  }
  .modal {
    & > * {
      max-width: calc(100% - 40px);
      max-height: calc(100% - 40px);
    }
  }
  .sign {
    &-title {
      @include setFont(Roboto, normal, 500, 20px, 24px);
    }

    &-button {
      @include setFont(Roboto, normal, 500, 16px, 19px);
      padding: 10px 16px;
    }
  }
}

@media(max-width: 375px) {
  .main {
    max-width: 375px;
    padding: 0 5px;
  }
}

@media (max-width: 375px) and (orientation: landscape) {
  body {
    transform: rotate(-90deg);
  }
}