@media(max-width: 1025px) {
  max-width: 1540px;

  &__subtitle {
    &.input {
      font-size: 18px;
      line-height: 19px;
      margin: 34px 0 55px 0;
    }
  }

  &__intro {
    &.feedback {
      margin-top: 211px;
    }
  }

  &__label {
    grid-template-columns: repeat(2, minmax(150px, 225.06px));
    column-gap: 31.36px;
    margin-bottom: 54px;

    &:nth-of-type(4) {
      row-gap: 5px;
    }

    &-legend {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &__form {
    margin-right: 156px;
  }

  &__info {
    &.input {
      padding-top: 202px;
    }

    &.feedback {
      padding-top: 256px;
    }

    &-title {
      font-size: 42px;
      line-height: 50px;
    }

    &-subtitle {
      font-size: 26px;
      line-height: 33px;
      margin-bottom: 3.93px;
    }

    svg {
      width: 636.5px;
      height: 705.76px;
      bottom: -332px;
      margin-left: 64px;
    }
  }
}