.portfolio {
  width: 100%;
  max-width: 1319px;
  padding-bottom: 50px;

  &__title,
  &__subtitle {
    color: #DDDDDD;
  }

  &__title {
    @include setFont(Roboto, normal, 500, 22px, 24px);
    margin: 54px 0 32px 0;
  }

  &__subtitle {
    @include setFont(Roboto, normal, 400, 20px, 24px);
  }

  &__cards {
    display: grid;
    margin-top: 80px;

    &-list {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 40px;
    }

    &-item {
      @include setFlex(space-between, center, row);
      width: 100%;
      border: 1px solid #2E2E2E;
      border-radius: 12px;
    }
  }

  &__params {
    &-item {
      @include setFlex(flex-start, center, row);
      background-color: transparent;

      & svg {
        fill: #C1C1C1;

        &:hover {
          fill: $darkYellowColor;
        }
      }

      span {
        @include setFont(Roboto, normal, 400, 16px, 19px);
        color: $grayColor;
        margin-left: 14px;
        text-transform: capitalize;
      }
    }
  }

  &__share {
    &-list {
      display: grid;
      grid-template-columns: 12px 21px 22px 22px;
      grid-column-gap: 32px;
      position: relative;

      &_clipboard {
        display: none;
        position: absolute;
        top: 0;
        left: 0px;
        transform: translateY(-110%);
        justify-content: center;
        align-items: center;
        width: 194px;
        height: 42px;
        border-radius: 6px;
        @include setFont(Roboto, regular, 400, 18px, 19px);
        color: $lightGrayColor;
        background-color: $darkGrayColor;
        transition: linear opacity 0.4s;
        opacity: 0;

        &.active {
          display: flex;
          opacity: 1;
        }
      }
    }

    &-item {
      display: flex;
      background-color: transparent;

      & svg {
        cursor: pointer;
        min-height: 20px;
        min-width: 22px;
        width: auto;
        height: auto;
        fill: #C1C1C1;
      }
    }

    &-item svg:hover {
      & path {
        fill: $darkYellowColor;
      }
    }
  }

  &__postcards {
    @include setFlex(center, center, row);

    &-intro {
      @include setFlex(center, center, column);
      padding: 19px 0 19px 61px;
    }

    &-content {
      display: grid;
      grid-template-columns: repeat(2, 207px);
      grid-column-gap: 62px;
    }

    &-footer {
      @include setFlex(space-between, center, row);
      width: 100%;
    }

    &-item {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 12px;
      align-items: center;
      justify-content: center;
    }

    &-block,
    &-img {
      width: 100%;
      background-color: #45454B;
    }

    &-block {
      border: 0.73px solid $lightGrayColor;
    }

    &-img {
      object-fit: cover;
      min-height:146px;
    }

    &-subtitle {
      @include setFont(Roboto, normal, 400, 15px, 18px);
      color: $grayColor;
      text-align: center;
    }

    &-delete {
      display: flex;
      height: 100%;
      min-width: 59px;
      padding: 0 20px;
      border-left: 1px solid #2E2E2E;
    }

    &-video {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-left: 62px;
      max-width: 207px;
      width: auto;

      // overflow: hidden;
      & .portfolio__postcards-subtitle {
        margin-bottom: 10px;
      }

      & .video {
        max-width: 196px;
        max-height: 154px;
        padding-left: 1px;
        padding-right: 1px;
        padding-top: 1px;

        &__react-player {
          max-width: 186px;
        }

        &__title {
          color: #b6b6b6;
          &-text {
            font-size: 6px;
            font-weight: 400;
            margin-top: 0;
            line-height: 15px;
            padding-top: 3px;

          }

          &-logo {
            height: 8px;
            padding-bottom: 0px;
          }
        }

        & .videoTimeline {
          bottom: 14px;
          left: 0;
        }
      }
    }
  }

  &__original {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 12px;
    align-items: center;
    justify-content: center;

    &-container {
      display: grid;
      grid-template-columns: repeat(2, minmax(auto, 98px));
      grid-column-gap: 3px;
      padding: 3px 3px 11px 3px;
      background: linear-gradient(126.6deg, rgba(255, 255, 255, 0.096) 28.69%, rgba(255, 255, 255, 0) 100%);
      border: 0.73px solid $lightGrayColor;
    }

    &-block {
      width: 100%;
    }

    &-img {
      width: 100%;
      height: 132px;
      object-fit: cover;
    }
  }

  &__details {
    height: 100%;
    max-height: 244px;
    display: grid;
    grid-template-rows: 1fr;
    grid-row-gap: 35px;
    margin-right: 31px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
    }

    &-title {
      @include setFont(Roboto, normal, 500, 16px, 19px);
      color: $lightGrayColor;
      margin-bottom: 8px;
    }

    &-subtitle {
      @include setFont(Roboto, normal, 400, 15px, 18px);
      color: $grayColor;
      text-transform: capitalize;
    }
  }

  @import "media_queries/index";

}