.zoom {
    &__image {
        &-wrapper {
            height: 80vh;
            max-width: 100%;
            position: relative;

            &-close {
                position: absolute;
                left: -40px;
                top: -10px;

                & button {
                    background-color: transparent;
                }
            }
        }

        &.image {
            height: 100%;
            width: 100%;
        }

        &-share {
            position: absolute;
            display: flex;
            flex-direction: row;
            bottom: 1vh;
            left: 20px;


            &-button {
                width: 4vh;
                height: 4vh;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #858798;
                border-radius: 50%;
                background-color: transparent;
                margin-right: 10px;

                & svg {
                    fill: $darkYellowColor;
                    width: 2vh;
                    height: 2vh;
                }
            }
            
        &-download {
            position: absolute;
            right: 20px;
            bottom: 1vh;
        }
        }
    }

    &__wrapper {
        min-width: 100vw;
        min-height: 100vh;
        position: fixed;
        display: none;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        background-color: $webBgColor;
        z-index: 100;

        &.active {
            display: flex;
        }


    }
}