.message{
    display: none;
    position: fixed;
    overflow: hidden;
    min-height: 100vh;
    min-width: 100vw;
    transition: .3s linear; 
    z-index: 3000;
// for screen that lower then 699 in height and width less 1025
    @media (max-height: 699px) and (max-width:1025px) and (orientation: landscape) {
        display: flex;
     &__main{
         span{
             &:before{
                content: 'This website can only be viewed in Portrait mode';
            }
        }
    }
 }

 // for screen width from 699 to 1025 and height 
    @media (min-width:699px) and (max-width: 1025px) and (orientation: portrait) {
        display: flex;
    span{
        &:before{
            content: 'This website can only be viewed in Landscape mode';
        }        
    } 
 }
//  @media only screen 
//   and (max-device-height: 400px) 
//   and (max-device-width: 850px) 
//   and (-webkit-min-device-pixel-ratio: 2) { 
//     display: flex;
//     span{
//         &:before{
//             content: 'This website can only be viewed in Portrait mode';
//         }        
//     } 
// }
// @media only screen 
// and (max-device-height: 900px) 
// and (max-device-width: 400px) 
// and (-webkit-min-device-pixel-ratio: 2) { 
//   display: none;
  
// }

  &__background{
    min-height: 100vh;
    min-width: 100vw;
    background-color: #3b3b43c5;
    backdrop-filter: blur(4px);
  }
  &__main{
    @include setFlex(center, center,column);
    font-family: 'Roboto';
    font-style: 'normal';
    font-weight: 'normal';
    text-align: center;
    position: fixed;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
    min-height: 30vh;
    min-width: 85vw;
    background-color: #3B3B43;
    color: #FFFFFF;
    border: 1px solid $darkYellowColor;
    border-radius: 20px;
 }

}