.zoom {
    &__wrapper {
        min-width: 100vw;
        min-height: 100vh;
        position: fixed;
        display: none;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        background-color: $webBgColor;
        z-index: 100;

        &.active {
            display: flex;
        }
    }

    &__react-player {
        // background-color: #767676;
        height: 80vh;
        max-width: 95vw;
        aspect-ratio: 1/1;
        margin: 40px;
        border: 2px solid #C1C1C1 !important;
        background-image: linear-gradient(126.6deg, rgba(255, 255, 255, 0.096) 28.69%, rgba(255, 255, 255, 0) 100%) !important;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        position: relative;

        &-close {
            position: absolute;
            left: -40px;
            top: -10px;

            & button {
                background-color: transparent;
            }
        }

        &-share {
            position: absolute;
            display: flex;
            flex-direction: row;
            bottom: 1vh;
            left: 20px;


            &-button {
                width: 4vh;
                height: 4vh;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #858798;
                border-radius: 50%;
                background-color: transparent;
                margin-right: 10px;
                & svg {
                    fill: $darkYellowColor;
                    width: 2vh;
                    height: 2vh;
                }
            }
        }

        &-download {
            position: absolute;
            right: 20px;
            bottom: 1vh;
        }
        &-clickable{
            width: 100%;
            height: 73vh;
            position: absolute;
            top: 0;
            left: 0;
            background-color: transparent;
            z-index: 100;
        }
        & .videoTimeline{
            bottom: 6.2vh
            
        }
    }
}