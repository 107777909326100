@media(max-width: 1025px) {
  
  // max-width: 819px;
  // margin-right: 0;

  &__title {
    font-size: 18px;
    line-height: 15px;
    margin: 34px 0 18px 0;
  }
  &__subtitle {
    font-size: 14px;
    line-height: 24px;  
  }
  &__cards {
    margin-top: 28px;

    &-list {
      grid-row-gap: 24.66px;
    }

    &-item {
      border-radius: 6.703px;
      border-width: 0.558583px;
    }
  }

  &__postcards {
    &-intro {
      padding: 12.42px 0 12.42px 5.5px;
      min-width: 292px;
    }

    &-content {
      grid-template-columns: repeat(2, minmax(auto, 128.53px));
      grid-column-gap: 30px;
    }

    &-item {
      grid-row-gap: 5.47px;
    }

    &-block {
      height: 91.9px;
      border-width: 0.558583px;
    }

    &-subtitle {
      font-size: 12px;
      line-height: 14px;
    }

    &-delete {
      padding: 0 8.48px 0 10.02px;
      border-left-width: 0.558583px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
    &-video{
      max-width: 115px;
      min-width: 115px;
      padding-left: 0;
      padding: 0;
      margin-left: 30px;
      & .portfolio__postcards-subtitle{
        margin-bottom: 7px;
      }
       .video{
        max-width: 126px;
        max-height: 90px;
        &__react-player{
          max-width: 107px; 
        }
        &__title{
          padding-right: 5px;
          &-text{
            font-size: 6px;
            font-weight: 400;
            margin-top: 0;
            line-height: unset;
          }
          &-logo{
            height: 7px;
          }
        }
        & .videoTimeline{
          bottom: 10px; 
        }
      }
    }
  }

  &__original {
    grid-row-gap: 5.47px;

    &-container {
      grid-template-columns: repeat(2, minmax(auto, 60.85px));
      grid-column-gap: 1.86px;
      padding: 2.48px 2.48px 6.45px 2.48px;
    }

    &-block {
      height: 81.96px;
    }

    &-img {
      height: 100%;
    }
  }

  &__details {
    grid-row-gap: 20.16px;
    margin-right: 17.1px;

    &-title {
      font-size: 13px;
      line-height: 15px;
      margin-bottom: 2.59px;
    }

    &-subtitle {
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__share {
    &-list {
      display: grid;
      grid-template-columns: 7.45px repeat(3, 16px);
      grid-column-gap: 23px;

      button[aria-label="facebook"] {
        svg {
          width: 7.45px;
          height: 16px;
        }
      }
    }

    &-item {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  &__params {
    &-item {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}