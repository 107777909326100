@media(max-width: 699px) {
  padding: 0 20px;
  &__button {
    position: absolute;
    right: 20px;
    height: 40px;
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.toggle {
      &.active{
        position: fixed;
      }
      svg {
        width: 20px;
        height: 12px;
      }
    }
  }
  &__intro {
    padding-top: 25px;
  }
  &__content {
    max-width: 335px;
    padding: 6px 0;
    margin: 0 auto;
  }
  &__logo {
    width: 102px;
  }
}