@media(max-width: 1025px) {
  display: flex;
  height: auto;
  padding: 0;
  border-right: none;
  flex: 1 0 auto;
  z-index: 1;

  &__intro {
    @include setFlex(center, flex-start, column);
    height: 100%;

    &.active {
      justify-content: space-between;
    }
  }

  &__header {
    @include setFlex(flex-start, center, row);
  }

  &__profile {
    @include setFont(Roboto, normal, 500, 24px, 24px);
    color: $darkYellowColor;
  }

  &__info {
    margin-left: 16px;

    &-title {
      @include setFont(Inter, normal, 500, 16px, 19px);
      color: $darkYellowColor;
      margin-bottom: 2px;
      text-transform: capitalize;
    }

    &-subtitle {
      @include setFont(Inter, normal, 500, 13px, 16px);
      color: $lightGrayColor;
      text-transform: capitalize;
    }
  }

  &__point {
    &-list {
      grid-row-gap: 0;
      margin: 38px 0 36px 0;
    }

    &-item {
      @include setFlex(center, center, row);
      width: 62px;
      height: 58px;
      border-radius: 13px;
      background-color: transparent;

      &, & * {
        transition: .3s linear;
      }

      &:nth-child(2) {
        svg {
          position: relative;
          left: 1px;
        }
      }

      &.selected {
        background-color: #33333C;

        path {
          fill: $darkYellowColor;

          &:nth-last-child(1), &:nth-last-child(2) {
            stroke: $darkYellowColor;
          }
        }
      }

      &.disabled {
        pointer-events: none;

        path {
          fill: #3B3B43;

          &:nth-last-child(1), &:nth-last-child(2) {
            stroke: #3B3B43;
          }
        }
      }
    }
  }

  &-mobile {
    &__point {
      &-item {
        @include setFlex(flex-start, center, row);
        text-decoration: none;


        // disable icons in the mobile menu color: ;
        // &.selected > *:first-child {
        //   background-color: #33333C;

        //   path {
        //     fill: $darkYellowColor;

        //     &:nth-last-child(1), &:nth-last-child(2) {
        //       stroke: $darkYellowColor;
        //     }
        //   }
        // }

        &.disabled {
          pointer-events: none;

          & .aside-mobile__point-title {
            color: #3B3B43;
          }

          path {
            fill: #3B3B43;

            &:nth-last-child(1), &:nth-last-child(2) {
              stroke: #3B3B43;
            }
          }
        }
      }

      &-icon {
        @include setFlex(center, center, row);
        width: 60px;
        height: 56px;
        border-radius: 13px;
        background-color: transparent;
        margin-right: 6px;
        &.active{
          fill: $darkYellowColor;


            &:nth-last-child(1), &:nth-last-child(2) {
              stroke: $darkYellowColor;
            }
        }

        &, & * {
          transition: .3s linear;
        }
      }

      &-title {
        @include setFont(Inter, normal, normal, 15px, 18px);
        color: $grayColor;
        text-transform: capitalize;
        &.active{
          color: $darkYellowColor;
        }
        span {
          text-transform: none;
        }
      }
    }
  }

  &__exit {
    @include setFlex(flex-start, center, row);
    @include setFont(Inter, normal, normal, 15px, 18px);
    color: $lightGrayColor;
    transition: .3s linear;
    text-transform: capitalize;

    &.unauthorized {
      color: $darkYellowColor;
    }
  }
}