.home {
  padding-top: 239px;

  &__intro {
    padding: 0 52px;
  }

  &__title, &__subtitle {
    color: $lightGrayColor;
    word-break: break-word;
  }

  &__title {
    @include setFont(Roboto, normal, 500, 33px, 36px);
    text-transform: uppercase;

    span {
      color: $darkYellowColor;
    }
  }

  &__subtitle {
    @include setFont(Roboto, normal, normal, 20px, 28px);
    width: 704px;
    max-width: 100%;
    margin: 20px 0 42px 0;
  }

  .link {
    @include setFlex(center, center, row);
    height: 44px;
    border-radius: 8px;
    text-decoration: none;
    text-transform: capitalize;
  }

  &__footer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, 197px);
    grid-column-gap: 44px;
  }

  &__link {
    &.start, &.demo-link {
      @extend .link;
    }

    &.start {
      @include setFont(Inter, normal, bold, 18px, 22px);
      color: #474747;
      background-color: $darkYellowColor;
    }

    &.demo-link {
      @include setFont(Inter, normal, 600, 16px, 19px);
      color: $lightGrayColor;
      background-color: transparent;
      border: 1.5px solid $lightGrayColor;
    }
  }

  @import "media_queries/index";
}