@media(max-width: 1440px) {
  &__button {
    &.return {
      width: 30px;
      height: 30px;

      svg {
        width: 6.09px;
        height: 11.11px;
      }
    }

    &.generate {
      span {
        font-size: 12px;
        line-height: 14px;
      }

      svg {
        width: 16.11px;
        height: 16.11px;
        margin-bottom: 4.03px;
      }
    }
  }
  &__title {
    font-size: 20px;
    margin: 54.36px 0 26px 0;
  }
  &__subtitle {
    font-size: 18px;

    &.anonymize, &.complete, &.share {
      margin-left: 26px;
    }

    &.complete, &.share {
      font-size: 20px;
      line-height: 19px;
    }
  }
  &-modal {
    &__body {
      width: 632.97px;
      min-height: auto;
      max-height: 590.29px;
    }

    &__content {
      padding: 26px 0;
    }

    &__button {
      &.exit {
        top: 25.77px;
        left: 25.77px;

        svg {
          width: 12.08px;
          height: 12.08px;
        }
      }

      &.photo {
        width: 69.26px;
        height: 69.26px;
        top: -25px;

        svg {
          width: 40.26px;
          height: 31.69px;
        }
      }

      &.use {
        width: 109.2px;
        height: 31px;
        font-size: 16px;
        line-height: 19px;
      }

      &.retake {
        font-size: 15px;
        line-height: 18px;

        svg {
          width: 16.18px;
          height: 10.32px;
        }
      }
    }

    &__title {
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 12.43px;
    }

    &__content {
      video, img {
        width: 344.67px;
        height: 452.58px;
        border-radius: 10.7152px;
      }
    }

    &__footer {
      max-width: 344.67px;
      margin-top: 19.47px;
    }
  }
  &__intro {
    &.anonymize {
      margin-top: 64px;
    }

    &.complete {
      margin-top: 35.38px;
    }
  }
  &__face {
    &-content {
      &.anonymize {
        svg {
          width: 25.71px;
          height: 25.71px;
          margin-bottom: 4.86px;
        }
      }
    }
  }
  &__label {
    &.anonymize {
      height: 73.12px;
      font-size: 16px;
      line-height: 19px;
      border-width: 1.61px;
    }
  }
  &__photo {
    &-intro {
      border-width: 1.5px;
      padding: 3.5px;
      border-radius: 19.3208px;

      &.complete {
        margin: 11.28px 0 31.41px 0;
      }
    }

    &-img, &-overlay {
      border-radius: 17.7061px;
    }

    &-img {
      height: 296px;
    }
  }
  &__error {
    font-size: 16px;
    line-height: 18px;
    height: 18px;
    margin: 18px 0 43px 0;
  }
  &__content {
    &.anonymize {
      grid-template-columns: minmax(170px, 229px) 229px;
      grid-column-gap: 45px;
    }

    &.complete {
      grid-template-columns: 229.56px 315.43px;
      grid-column-gap: 220.7px;
      margin-top: 47px;
    }
  }
  &__switch {
    &-label {
      font-size: 16px;
      line-height: 19px;
    }
  }
  &__original {
    &-title {
      font-size: 16px;
      line-height: 19px;
    }

    &-item {
      width: 229.59px;
      height: 306px;
      border-width: 1.5px;
      padding: 3.5px;
      border-radius: 19.3208px;
    }

    &-img {
      border-radius: 17.7061px;
    }

    &-container {
      margin: 11.28px 0 12.89px 0;
    }

    &-arrow {
      svg {
        width: 13.69px;
        height: 29px;
      }
    }
  }
  &__pagination {
    padding: 0 45px;

    & .demo__original-img {
      border-radius: 4.8616px;
    }

    &-list {
      grid-template-columns: repeat(3, minmax(auto, 48.33px));
      grid-column-gap: 1.8px;
    }

    &-item {
      height: 61.22px;
      padding: 4.83px;
    }
  }
  &__share {
    margin-top: 31px;
    margin-left: 0;
    max-width: calc(100% - 118px);
  }
}