@media(max-width: 699px) {
  max-width: 295px;
  margin: 0 auto;
  padding: 0;
  &__subtitle {
    &.input {
      &, span {
        font-size: 20px;
        line-height: 26px;
      }

      margin: 30px 0 39px 0;
    }
  }
  &__label {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 47px;
    margin: 0 0 47px 0;

    &-legend {
      font-size: 15px;
      line-height: 24px;
    }

    &:nth-of-type(4) {
      row-gap: 4px;
    }

    &:last-of-type {
      margin-bottom: 24px;
    }
  }
  &__intro {
    &.input {
      justify-content: center;
    }

    &.feedback {
      margin-top: 81px;
    }
  }
  &__info {
    &.input, &.feedback {
      display: none;
    }
  }
  &__form {
    width: 100%;
    margin-right: 0;
  }
  &__feedback {
    &-title {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 16px;
    }

    &-subtitle {
      max-width: 100%;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
    }
  }
}