@media(max-width: 1025px) {
  padding: 22px 40px 14.79px 40px;
  &__button {
    height: 40px;
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.toggle {

      span {
        display: block;
        width: 20px;
        height: 3px;
        margin-bottom: 4px;
        transition: 0.4s;
        border-radius: 3px;
        background: $grayColor;
      }

      &.active span {
        background: $grayColor;

        &:nth-child(1) {
          transform: rotate(-45deg) translate(-7.5px, 9px);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(45deg) translate(-7.5px, -9px);
          position: relative;
          top: 10px;
        }
      }
    }
  }
  &__intro {
    padding-top: 22px;
  }
  &__content {
    padding: 0;
  }
  &__logo {
    width: 121px;
  }
  &__button {
    &.toggle {
      transition: .3s linear;

      &.active {
        z-index: 1201;
      }
    }
  }
}