@media(max-width: 1025px) {
  &__label {
    & > span::before {
      width: 18.58px;
      height: 18.58px;
      border-radius: 3.09735px;
      margin-right: 5.75px;
    }
  }
  &__title {
    font-size: 12px;
    line-height: 19px;

    a {
      margin: 0;
    }
  }
}