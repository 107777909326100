.sign-up {
  position: fixed;
  &__button {
    &.exit {
      background-color: transparent;
      position: absolute;
      top: 28px;
      left: 35px;
      z-index: 2;
    }

    &.login {
      @extend .sign-button;
    }

    &.send {
      @include setFont(Roboto, normal, 500, 14px, 24px);
      color: #BD9D2D;
      background-color: transparent;
    }

    &.sign {
      @extend .sign-button;
    }
  }

  &__body {
    @include setFlex(space-between, flex-start, row);
    width: 904px;
    height: 638px;
    overflow: hidden;
    position: relative;
    border-radius: 24px;
    ::-webkit-scrollbar {
      width: 0;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
      background: #FF0000;
  }
  }

  &__content {
    width: 100%;
    height: 100%;
    background: url("../../../assets/img/sign-in__bg.svg") center no-repeat;
    background-size: cover;
    position: sticky;
    overflow-x: hidden;
  }

  &__intro {
    padding: 60px 0 0 131px;
    position: relative;
    z-index: 1;
  }

  &__title {
    @extend .sign-title;
    text-transform: capitalize;

    svg {
      margin-left: 10px;
    }

    &.input {
      margin: 32px 0;
    }

    &.feedback {
      margin: 75px 0 20px 0;
    }

    &.create {
      margin: 32px 0 22px 0;
    }
  }

  &__subtitle {
    @include setFont(Roboto, normal, 400, 16px, 24px);

    &, a {
      color: #FFFFFF;
    }

    a {
      font-weight: 900;
      text-decoration: none;
    }

    &.feedback {
      width: 381px;
      max-width: 100%;
    }

    &.create {
      &.first {
        margin-bottom: 20px;
      }
    }
  }

  &__description {
    color: #C1C1C1;

    &.feedback {
      @include setFont(Roboto, normal, 400, 14px, 24px);
      max-width: 100%;
      width: 319px;
      margin: 100px 0 20px 0;
    }

    &.create {
      @include setFont(Roboto, normal, 400, 16px, 24px);
      width: 431px;
      max-width: 100%;
      margin: 4px 0 24px 0;
    }
  }

  &__label {
    margin: 0 0 32px 0;

    .MuiFormHelperText-root {
      margin-top: 2px;
    }

    &.double {
      display: grid;
      grid-template-columns: repeat(2, minmax(100px, 194px));
      grid-column-gap: 24px;
    }

    &:nth-child(3) {
      margin-bottom: 42px;
    }

    &:nth-child(4) {
      margin-bottom: 28px;
    }
  }

  &__forgot {
    width: 100%;
    text-align: right;
    margin-top: 4px;

    button {
      @include setFont(Roboto, normal, normal, 12px, 24px);
      color: #999999;
      background-color: transparent;
    }
  }

  &__error {
    @include setFont(Roboto, normal, 500, 16px, 24px);
    color: $errorColor;
    margin: 8px 0 30px 0;
    transition: .3s linear;
    opacity: 0;
    visibility: hidden;

    &.create {
      height: 24px;
      margin: 19px 0;
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  &__title {
    @extend .sign-title;
  }

  &__footer {
    &, * {
      @include setFont(Roboto, normal, normal, 14px, 24px);
    }

    color: #FFFFFF;
    margin-top: 20px;

    button {
      color: $darkYellowColor;
      font-weight: 500;
      margin-left: 8px;
      background-color: transparent;
    }
  }

  @import "media_queries/index";
}