.header {
  padding: 36px 50px 0 63px;

  &__intro {
    width: 100%;
  }

  &__content {
    @include setFlex(space-between, center, row);
  }

  &__button {
    &.toggle {
      background-color: transparent;
    }
  }

  @import "media_queries/index";
}