// <===pairs/anonymization===>
@media(min-width: 2559px) {
    &.pairs {
        margin: 81px 0 120px 0;
    }

    .pairs {
        &__title {
            font-size: 36px;
            line-height: 51px;
            margin-bottom: 47px;
        }

        &__subtitle {
            font-size: 36px;
            line-height: 48px;
        }

        &__intro {
            justify-content: unset;
            max-width: none;
            margin-top: 51px;

            &.choose {
                margin-top: 116px;
            }

            &.anonymize {
                grid-template-columns: 53px minmax(auto, auto);

            }

            &-container {
                &.anonymize {
                    grid-template-columns: repeat(2, 1fr);

                }
            }
        }

        &__check {
            &-title {
                font-size: 28px;
                line-height: 32px;
                margin-bottom: 28px;
            }

            &-list {
                grid-row-gap: 21px;
                margin-right: 208px;
            }

            &-item {
                font-size: 28px;
                line-height: 32px;
                font-weight: 400;

                & .check-point {
                    width: 29px;
                    height: 29px;

                    & svg {
                        width: 13px;
                        height: 13px;
                    }
                }
            }
        }

        &__select {
            &-intro {
                grid-template-columns: repeat(2, 407px);
            }

            // .demo-modal with camera
            & .demo-modal {
                &__body {
                    width: 1054px;
                    min-height: 982px;
                }

                &__content {
                    padding: 60px 0 40px 0;

                    & video {
                        width: 572px;
                        height: 752px;
                    }
                }

                &__button {
                    &.exit {
                        & svg {
                            width: 23px;
                            height: 23px;
                        }
                    }
                }
            }

            // AnonymizationChooseUploadImg modal 
            & .modalChoose {
                &-wrapper {
                    min-width: 1054px;
                    height: 982px;

                    &__icon {
                        width: 194px;
                        margin-bottom: 40px;
                    }

                    &__title {
                        font-size: 42px;
                        margin-bottom: 40px;
                    }

                    &__subtitle {
                        font-size: 32px;
                        line-height: 50px;
                        margin-bottom: 50px;
                    }

                }

                &__button {
                    &-container {
                        width: 383px;
                        height: 121px;
                        margin-bottom: 39px;
                    }

                    & img {
                        width: 40px;
                        height: 31px;
                        margin-bottom: 14px;

                    }

                    & p {
                        font-size: 24px;
                        line-height: 28px;
                    }
                }

            }
        }

        &__face {
            height: 550px;

            &.clickable {
                height: 544px;
            }

            &-content {
                & span {
                    font-size: 24px;
                    line-height: 28px;
                }

                & svg {
                    width: 42px;
                    height: 42px;
                }
            }
        }

        &__process {
            &.select {
                grid-template-columns: minmax(690px, 700px) minmax(863px, 870px);
                column-gap: 275px;
            }

            &-pair {
                grid-template-columns: repeat(2, minmax(auto, 410px));
                grid-column-gap: 52px;
            }

            &-title {
                font-size: 32px;
                line-height: 34px;
                margin-bottom: 20px;
            }

            &-intro {
                &.select {
                    & p {
                        font-size: 32px;
                        line-height: 38px;
                        margin-bottom: 10px;
                        // text-align: center;

                        & svg {
                            height: 30px;
                            width: 23px;
                            margin-right: 13px;

                        }
                    }
                }
            }

            &.survey {
                grid-template-columns: repeat(2, minmax(799px, 801px));
            }
        }

        &__filter {
            &-subtitle {
                &.survey {
                    font-size: 32px;
                    line-height: 37px;
                }
            }

            &-content {
                &.survey {
                    margin-bottom: 99px;
                }
            }

            &-list {
                &.select {
                    grid-template-columns: repeat(2, minmax(auto, 318px));
                    column-gap: 50px;
                    row-gap: 41px;
                }

                &.survey {
                    margin: 37px 0 35px 0;

                }
            }

            &-label {
                &.survey {
                    font-size: 28px;
                    line-height: 50px;
                }
            }

            &-description {
                &.survey {
                    max-width: 800px;
                    width: 798px;
                    min-height: 145px;
                    font-size: 28px;
                    line-height: 43px;

                    &::placeholder {
                        font-size: 24px;
                        line-height: 28px;
                    }
                }
            }

            &-comment {
                &.survey {
                    font-size: 24px;
                    line-height: 48px;
                }
            }

            &-item {
                &.select {
                    height: 228px;

                    & span {
                        font-size: 28px;
                        line-height: 33px;
                    }
                    & svg{
                         width: 120px;
                         height: 40px;
                    }
                    & svg:last-child {
                        width: 40px;
                         height: 40px;
                    }
                }
            }
        }

        &__candidates {
            &-list {
                width: 1095px;
                grid-template-columns: repeat(auto-fit, 270px);

            }

            &-img {
                height: 158px;
            }

            &-content {
                grid-template-columns: repeat(2, 112px);
                grid-column-gap: 10.6px;
                padding: 10.6px;
            }

            &-option {
                width: 50px;
                height: 50px;
                font-size: 26px;
                line-height: 31px;
            }
        }

        &__button {
            &.next {
                &.choose {
                    font-size: 32px;
                    line-height: 39px;

                    & svg {
                        width: 37px;
                        height: 21px;
                    }
                }

                &.select {
                    & svg {
                        width: 37px;
                        height: 21px;
                    }

                    font-size: 32px;
                    line-height: 39px;
                }

                &.survey {
                    margin-top: 150px;
                    width: 258px;
                    height: 78px;
                    font-size: 32px;
                    line-height: 39px;
                }
            }

            &.clear {
                font-size: 24px;
                line-height: 28px;
            }

            &.option {
                font-size: 24px;
                line-height: 28px;
                width: 258px;
                height: 78px;
            }

            &.generate {
                width: 231px;
                height: 158px;
                font-size: 26.6667px;
                line-height: 29px;
                border: 0.7px solid #DDDDDD;


                & svg {
                    height: 37px;
                    width: 37px;
                }
            }

            &.anonymization {
                width: 372px;
                height: 58px;
                font-size: 24px;
                line-height: 29px;
            }

        }

        &__tab {
            &-face {
                & svg {
                    min-height: 472px;
                    min-width: 338px;
                }
            }

            &-subtitle {
                font-size: 28px;
                line-height: 42px;
            }
        }

        &__selected {
            &-title {
                font-size: 32px;
                line-height: 38px;
                margin: 0 0 10px 0;
            }

            &-subtitle {
                font-size: 28px;
                line-height: 32px;
            }

            &-description {
                font-size: 28px;
                line-height: 42px;
                margin-top: 32px;
            }
        }

        &__container {
            &.select {
                max-width: none;
            }

            &.survey {
                max-width: none;
            }

            &.share {
                &-main {

                    // share step 
                    & .pairs {
                        &__container {
                            &.subtitle {
                                font-size: 27px;
                                line-height: 36px;
                            }

                            &.result {
                                height: 570px;
                                width: 795px;

                                & img {
                                    max-width: 795px;
                                }

                                & .video {
                                    height: 569.63px;

                                    &__loading {
                                        width: 769px;
                                        height: 520px;

                                        &-bar-count {
                                            font-size: 16px;
                                            line-height: 33px;
                                        }
                                    }

                                    &__react-player {
                                        height: 521px;
                                        max-width: 795px;
                                    }

                                    &__title {
                                        align-items: end;

                                        &-text {
                                            font-size: 17px;
                                            line-height: 20px;
                                        }

                                        &-logo {
                                            height: 30px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // share step 
        &__share {
            &-title {
                font-size: 46px;
                line-height: 35px;
                margin-top: 46px;

            }

            &-subtitle {
                font-size: 32px;
                line-height: 35px;
                margin: 35px 0 12px 0;
            }

            &-description {
                font-size: 32px;
                line-height: 50px;
            }
        }

        &__error {
            font-size: 28px;
            line-height: 32px;
            margin: 37px 0 49px 0;
        }
    }
}