.video {
    @include setFlex(space-evenly, center, column);
    min-width: max-content;
    height: 406px;
    border: 1px solid #767676;
    background-color: #45454B;
    position: relative;

    &__react-player {
        width: 100%;
        max-width: 573px;
        height: 373px;
        border-radius: 4px;
        overflow: hidden;

        & video {
            height: 109% !important;
        }
    }

    &__loading {
        @include setFlex(center, center, column);
        width: 551px;
        height: 365px;
        background-color: $webBgColor;
        position: relative;

        &-container {
            width: 162px;
            position: relative;
            height: 120px;
            margin-bottom: 26px;

        }

        &-background {
            position: absolute;
            // margin-bottom: 30px;
            width: 100%;
        }

        &-gear {
            width: 40%;
            position: absolute;
            bottom: -10%;
            left: -7%;
            animation: rotation 8s infinite linear;
        }

        &-logo {
            width: 60%;
            position: absolute;
            bottom: -16%;
            left: 50%;
        }

        &-bar {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 174px;
            height: 10px;
            margin-top: 28px;
            margin-left: 20px;

            & .MuiLinearProgress-root {
                background-color: $darkGrayColor;
                border-radius: 5px;
                height: 6px;
                width: 119px;
                margin-right: 4px;
            }

            & .MuiLinearProgress-bar {
                background-color: $darkYellowColor;
            }

            &-count {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 26px;
                color: $lightGrayColor;
            }

        }

        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(359deg);
            }
        }
    }

    &__title {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        color: $lightGrayColor;
        padding-right: 10px;

        &-text {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 14px;
            margin-right: 3px;
            margin-top: 3px;
        }

        &-logo {
            height: 22px;
            padding-bottom: 4px;
        }
    }

}


@media(max-width:1440px) {
    .video {
        height: 328px;
        padding-top: 3px;
        padding-left: 6px;
        padding-right: 5px;

        &__react-player {
            width: 100%;
            max-width: 432px;
            height: 292px;
            border-radius: 4px;
            overflow: hidden;
        }

        &__loading {
            width: 443px;
            height: 294px;

            &-container {
                width: 125px;
                height: 90px;
            }
        }

        & .pairs__share-subtitle {
            font-size: 16px;
        }

        &__title {
            &-text {
                font-size: 9.4px;
            }

        }
    }
}

@media(max-width:1155px) {
    .video {
        height: 255px;
        padding-top: 6px;

        &__loading {
            width: 355px;
            height: 235px;

            &-container {
                width: 93px;
                height: 69px;
                margin-bottom: 7px;
            }

            &-gear {
                width: 38%;
            }

            &-logo {
                width: 50%;
            }
        }

        &__title {
            &-logo {
                height: 17px;
            }
        }
    }
}

@media(max-width:699px) {
    .video {
        height: 328px;
        padding-top: 3px;
        padding-left: 6px;
        padding-right: 5px;

        &__react-player {
            width: 100%;
            min-width: 260px;
            max-width: 432px;
            height: 292px;
            border-radius: 4px;
            overflow: hidden;
        }

        &__loading {
            width: 443px;
            height: 294px;

            &-container {
                width: 125px;
                height: 90px;
            }
        }

        & .pairs__share-subtitle {
            font-size: 16px;
        }

        &__title {
            &-text {
                font-size: 9.4px;
            }

        }
    }
}