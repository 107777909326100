@media(max-width: 699px) {
  padding: 0 23px;
  margin-bottom: 180px;

  &>* {
    // max-width: 327px;
  }

  &__body {
    & .pairs__intro.choose {
      display: flex;
    }
  }

  &__button {
    &.return {
      width: 26px;
      height: 26px;
    }

    &.generate {
      span {
        font-size: 15px;
        line-height: 20px;
      }

      svg {
        width: 25px;
        height: 25px;
        margin-bottom: 9px;
      }
    }

    &.select {
      margin-top: 46px;
    }
  }

  &__title {
    line-height: 24px;
    margin: 36px 0 24px 0;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 38px 0;

    &.complete {
      margin: 0;
    }

    &.complete,
    &.share {
      font-size: 18px;
      line-height: 26px;
    }
  }

  &__container {
    &.share {
      margin-top: 36px;
    }
  }

  &-modal {
    &::-webkit-scrollbar {
      width: 0;
    }

    &__button {

      &.flash,
      &.facing-mode,
      &.photo,
      &.exit {
        background-color: transparent;
      }

      &.photo {
        position: unset;
        top: unset;
        box-shadow: unset;
        border-radius: unset;

        &,
        svg {
          width: auto;
          height: auto;
        }
      }

      &.exit {
        position: unset;
        top: unset;
        left: unset;

        svg {
          width: 15.26px;
          height: 15.26px;
        }
      }

      &.use {
        width: 118px;
        height: 41px;
        font-size: 18px;
        line-height: 21px;
      }

      &.retake {
        font-size: 18px;
        line-height: 21px;
        box-shadow: unset;
        border-radius: unset;

        span {
          margin-left: 5.9px;
          -webkit-text-stroke-width: 0.7px;
          -webkit-text-stroke-color: #858585;
        }

        svg {
          width: 20.6px;
          height: 13.32px;
          stroke: #858585;
          stroke-width: 0.7px;
        }
      }
    }

    &.modal>* {
      max-width: 100%;
    }

    &__body {
      width: 100vw;
      height: 100vh;
      max-height: none;
      border-radius: 0;

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    &__title {
      @include setFlex(center, center, row);
      width: 100%;
      min-height: 52px;
      font-size: 14px;
      line-height: 16px;
      background-color: #3B3B43;
      margin: 0;
      text-align: center;
    }

    &__media,
    &__content {
      width: 100%;
      height: 100%;
    }

    &__content {
      padding: 0;

      video,
      img {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }
    }

    &__candidates {
      width: 100%;
      max-width: 327px;
    }

    &__webcam {
      width: 100%;
      height: 100%;
      position: relative;
      overflow-y: hidden;

      &-header,
      &-footer {
        @include setFlex(space-between, center, row);
        width: 100%;
        position: absolute;
        z-index: 1;
      }

      &-header {
        padding: 19.87px 24.87px;
        top: 0;
      }

      &-footer {
        max-width: 100%;
        margin-top: 0;
        padding: 11px 26px;
        bottom: 0;
      }
    }

    &__footer {
      max-width: 100%;
      position: absolute;
      bottom: 0;
      margin-top: 0;
      padding: 22px 30px;
    }

    &__face {
      &-content {
        &.anonymize {
          margin-bottom: 0;
        }
      }

      &-intro {
        height: 100%;
        overflow: hidden;

        &.anonymize {
          & * {
            transition: inherit;
          }

          &>input {
            position: absolute;
            z-index: -1;
            opacity: 0;
          }

          &>span {
            display: inline-flex;
            align-items: center;
            user-select: none;
          }
        }
      }
    }
  }

  &__content {
    &.anonymize {
      grid-template-columns: minmax(auto, 161px);
      grid-column-gap: 0;

      & .pairs__face {
        height: 214px;
      }
    }

    &.complete {
      @include setFlex(center, center, column);
      width: 100%;
    }
  }

  &__photo {
    flex-direction: column;

    &-intro {
      padding: 4.5px;
      border-width: 1.5px;
      border-radius: 12.1418px;

      &.complete {
        margin: 0;
      }
    }

    &-img,
    &-overlay {
      border-radius: 9px;
    }

    &-img {
      height: 202px;
    }
  }

  &__intro {
    &.anonymize {
      margin-top: 34px;
    }

    &.complete {
      display: grid;
      grid-template-columns: 26px auto;
      column-gap: 14px;
      align-items: start;
      margin-top: 36px;
    }
  }

  &__error {
    font-size: 16px;
    line-height: 24px;
    height: 24px;
    margin: 34px 0;
    text-align: center;
  }

  &__label {
    &.anonymize {
      height: 74px;
      font-size: 14px;
      line-height: 16px;
      border-radius: 8px;
      border-width: 2px;

      svg {
        width: 30px;
        height: 30px;
        margin-bottom: 6px;
      }
    }
  }

  &__swiper {
    &-container {
      & .pairs__slider-button.zoom {
        right: -8px;
        bottom: -9px;
      }
    }
  }

  &__original {
    &-title {
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 10px;
    }

    &-container {
      display: grid;
      grid-template-columns: repeat(2, minmax(100px, 161px));
      column-gap: 5px;
      justify-content: unset;
      align-items: start;
    }

    &-item {
      width: 100%;
      height: 214px;
      padding: 4.5px;
      border-width: 1.5px;
      border-radius: 12.1418px;
    }

    &-container {
      margin: 0 0 20px 0;
      position: relative;
      grid-template-columns: repeat(2, minmax(161px, 161px));
    }

    &-content {
      border-radius: 12.1418px;
    }

    &-img {
      border-radius: 9px;
    }

    &-arrow {
      svg {
        width: 9px;
        height: 17px;
      }
    }
  }

  &__pagination {
    padding: 0;
    justify-content: center;

    &-list {
      grid-template-columns: repeat(3, minmax(auto, 54px));
      grid-column-gap: 4px;
      margin: 0 28px;
    }

    &-item {
      height: 69px;
      padding: 6px;
      border-radius: 6.03562px;
    }
  }

  &__panel {
    @include setFlex(space-between, center, row);
    width: 100%;
    max-width: 267px;
    padding: 0 27px;
    margin-top: 51px;
  }

  &__switch {
    flex-direction: column-reverse;

    &-label {
      font-size: 15px;
      line-height: 20px;
      margin-top: 8px;
    }
  }

  &__share {
    display: unset;
    max-width: 100%;
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    margin: 22px 0;

    &-title {
      font-size: 22px;
      line-height: 26px;
    }

    &-subtitle {
      font-size: 16px;
      line-height: 22px;
      margin: 10px 0;
    }

    &-description {
      font-size: 15px;
      line-height: 24px;
    }

    &-list {
      margin-top: 16px;
    }
  }
}