.copyMessage {
    &-wrapper {
        width: 422px;
        height: 160px;
        display: none;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 11px 16px;
        background-color: #3B3B43;
        border-radius: 10px;
        position: fixed;
        top: 31px;
        left: 50%;
        transform: translateX(-50%);

        &.active {
            display: flex;
        }
    }

    &__icons {
        width: 100%;
        display: flex;
        justify-content: space-between;

        & div {
            cursor: pointer;
        }

        &-close {}

        &-ok {}
    }

    &-title {
        width: 330px;
        text-align: start;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: $darkYellowColor;
    }

    &-text {
        width: 330px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
    }


}