@media(min-width: 2559px) {
    // home 
    padding-top: 297px;
    padding-left: 57px;

    &__intro {
        padding: unset;
    }

    &__title {
        font-size: 54px;
        line-height: 60px;
    }

    &__subtitle {
        font-size: 32px;
        line-height: 55px;
        margin: 36px 0 112px 0;
        width: 1116px;
    }

    &__footer {
        grid-template-columns: repeat(auto-fit, 264px);
        grid-column-gap: 52px;

        & .home__link {
            &.start {
                height: 62px;
                border-radius: 10px;
                font-size: 22px;
                line-height: 27px;
            }

            &.demo-link {
                height: 62px;
                border-radius: 10px;
                font-size: 22px;
                line-height: 27px;
                font-weight: 700;
            }
        }
    }
}