@media(min-width: 2559px) {
    // portfolio 2560 
    max-width: 2054px;

    .portfolio {
        &__title {
            font-size: 36px;
            line-height: 34px;
            margin: 90px 0 65px 0;
        }

        &__subtitle {
            font-size: 32px;
            line-height: 48px;
        }

        // card 
        &__cards {
            &-item {
                border: 3px solid #2E2E2E;
            }
        }

        &__postcards {
            &-content {
                grid-template-columns: repeat(2, 314px);
                grid-column-gap: 64px;
            }

            &-video {
                // video 
                max-width: none;
                margin-left: 64px;

                & .video__react-player {
                    max-width: 573px;
                }

                & .video {
                    max-width: 312px;
                    max-height: 232px;
                    min-width: 312px;
                }
            }

            & .portfolio__original {
                &-container {
                    grid-template-columns: repeat(2, minmax(auto, 148px));
                    grid-column-gap: 6px;
                    padding: 6px 6px 18px 6px;
                }

                &-img {
                    height: 200px;
                }
            }
        }

        &__share {
            &-list {
                grid-template-columns: repeat(4, 32px);
            }

            &-item {
                & svg {
                    min-height: 32px;
                    min-width: 32px;
                }
            }
        }

        &__params {
            &-item {
                & svg {
                    min-height: 32px;
                    min-width: 32px;
                }
            }
        }

        &__details {
            max-height: 367px;
            margin: 10px 31px 10px 0;

            &-title {
                font-size: 28px;
                line-height: 33px;
            }

            &-subtitle {
                font-size: 28px;
                line-height: 44px;
            }
        }
    }

    &__postcards {
        &-subtitle {
            font-size: 26px;
            line-height: 30px;
        }
    }
}