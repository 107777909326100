@media(max-width: 699px) {
  &__button {
    &.exit {
      top: 16px;
      left: 16px;

      svg {
        width: 13.1px;
        height: 13.1px;
      }
    }

    &.send {
      @include setFont(Roboto, normal, 500, 14px, 24px);
    }
  }

  &__body {
    height: 691px;
  }

  &__content {
    background-image: url("../../../../assets/img/sign-in__bg-375.svg");
  }

  &__intro {
    height: 100%;
    overflow: auto;
    padding: 48px 30px 17px 30px;
  }

  &__logo {
    width: 126.17px;
  }

  &__title {
    svg {
      margin-left: 10px;
    }

    &.input {
      margin: 30.1px 0 28px 0;
    }

    &.feedback {
      margin: 75px 0 20px 0;
    }

    &.create {
      margin: 30px 0 24px 0;
    }
  }

  &__subtitle {
    @include setFont(Roboto, normal, 400, 16px, 24px);

    &.create {
      @include setFont(Roboto, normal, 400, 16px, 26px);

      &.first {
        max-width: 220px;
        margin-bottom: 16px;
      }
    }
  }

  &__description {
    &.feedback {
      @include setFont(Roboto, normal, 400, 14px, 24px);
      max-width: 100%;
      width: 319px;
      margin: 100px 0 20px 0;
    }

    &.create {
      @include setFont(Roboto, normal, 400, 13px, 24px);
      width: 100%;
      max-width: 100%;
      margin: 2px 0 34px 0;
    }
  }

  &__label {
    margin: 0 0 26px 0;

    &.double {
      display: grid;
      grid-template-columns: repeat(1, auto);
      grid-column-gap: 0;
      grid-row-gap: 26px;
    }

    &:nth-child(3) {
      margin-bottom: 56px;
    }

    &:nth-child(4) {
      margin-bottom: 26px;
    }

    &.create {
      margin: 0 0 44px 0;

      &:nth-child(3), &:nth-child(4) {
        margin-bottom: 44px;
      }

      & .MuiFormHelperText-root {
        @include setFont(Roboto, normal, 400, 13px, 16px);
        margin-top: 4px;
      }
    }
  }

  &__forgot {
    width: 100%;
    text-align: right;
    margin-top: 4px;

    button {
      @include setFont(Roboto, normal, normal, 12px, 24px);
      color: #999999;
      background-color: transparent;
    }
  }

  &__error {
    @include setFont(Roboto, normal, 500, 16px, 24px);
    color: $errorColor;
    margin: 8px 0 30px 0;
    transition: .3s linear;
    opacity: 0;
    visibility: hidden;

    &.create {
      height: 24px;
      margin: 19px 0;
    }

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  &__footer {
    @include setFont(Roboto, normal, normal, 13px, 24px);
    margin-top: 28px;
  }
}