@media(min-width:2559px) {
    &__container {
        max-width: 900px;
    }

    &__title {
        font-size: 42px;
        line-height: 43px;
    }

    &__date {
        font-size: 20px;
        line-height: 32px;
    }

    &__list {
        row-gap: 56px;
    }


    &__article {
        row-gap: 17px;

        &-item {
            font-size: 24px;
            line-height: 38px;
        }

        &-title {
            font-size: 27px;
            line-height: 32px;
        }

        &-subtitle {
            font-size: 24px;
            line-height: 38px;
        }

        &-description {
            font-size: 24px;
            line-height: 38px;
        }

        &-container {
            &_title {
                font-size: 24px;
                line-height: 38px;
            }

            &_item {
                font-size: 24px;
                line-height: 38px;
            }
        }
    }
    &__footer{
        &-title{
            font-size: 27px;
            line-height: 32px;
        }
        &-mail{
            font-size: 27px;
            line-height: 32px;
        }
    }
}