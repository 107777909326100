@media(max-width: 699px) {
  margin-top: 50px;
  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  &__date {
    font-size: 14px;
    line-height: 12px;
  }
  &__list {
    margin: 30px 0 40px 0;
    row-gap: 40px;
  }
  &__article {
    &-title {
      font-size: 18px;
      line-height: 24px;
    }

    &-subtitle {
      font-size: 16px;
      line-height: 20px;
    }

    &-description {
      font-size: 16px;
      line-height: 20px;
    }

    &-list {
      padding-left: 68px;
    }
  }
  &__footer {
    &-title, &-mail {
      font-size: 16px;
      line-height: 20px;
    }
  }
}