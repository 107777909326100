.videoTimeline{
    position: absolute;
    bottom: 26px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 5%;
    background: linear-gradient(360deg, #232323 0%, rgba(35, 35, 35, 0.669038) 25%, rgba(35, 35, 35, 0) 100%);
    &__buttonPlay {
        width: 8px;        
    };   
    &__buttonPause {
        width: 8px;       
    };
    & svg{
        width: inherit;
        height: inherit;
    }
    & .video__loading-bar{
        margin: 0;
        width: 90%;
        & .MuiLinearProgress-root {
            background-color: $darkGrayColor;
            border-radius: 5px;
            height: 2px;
            width: 100%;
            margin-right: 4px;
        }

        & .MuiLinearProgress-bar {
            background-color: #FFFFFF;
            transition: transform 1s linear;
        }
    }
    @media(min-width:2559px){
        & .video__loading-bar{
            height: 61px;
            & .MuiLinearProgress-root{
                height: 5px;
            }
        }
        & .videoTimeline__buttonPlay, .videoTimeline__buttonPause{
            width: 20px;
        }
    }
}