@media(max-width: 699px) {
  max-width: 327px;
  margin: 0 auto;
  padding: 20px 0;
  &__intro {
    padding: 0;
  }
  &__title {
    @include setFont(Roboto, normal, 500, 24px, 36px);
  }
  &__subtitle {
    @include setFont(Roboto, normal, 400, 16px, 27px);
    margin: 22px 0 32px 0;
  }
  &__footer {
    grid-template-columns: minmax(auto, 176px);
    column-gap: 40px;
    row-gap: 32px;
  }
  .link {
    height: 43px;
  }
  &__link {
    text-align: center;

    &.start {
      @include setFont(Inter, normal, bold, 16px, 19px);
    }

    &.demo-link {
      @include setFont(Inter, normal, 600, 14px, 17px);
      border-width: 1px;
    }
  }
}