.terms {
  @include setFlex(center, center, row);
  width: 100%;
  margin-top: 74px;

  &__container {
    max-width: 662px;
  }

  &__title {
    @include setFont(Roboto, normal, 400, 28px, 24px);
    color: $lightGrayColor;
    margin-bottom: 19px;
  }

  &__date {
    @include setFont(Roboto, normal, 400, 15px, 24px);
    color: $grayColor;
  }

  &__list {
    display: grid;
    row-gap: 42px;
    margin: 42px 0;
  }

  &__article {
    display: grid;
    row-gap: 14px;
    word-break: break-word;

    &-title {
      @include setFont(Roboto, normal, 500, 20px, 24px);
      color: $lightGrayColor;
    }

    &-subtitle, &-description {
      text-align: justify;

      &, a {
        color: $grayColor;
      }
    }

    &-subtitle {
      @include setFont(Roboto, normal, 400, 18px, 28px);
    }

    &-description {
      @include setFont(Roboto, normal, 400, 16px, 26px);
      text-transform: uppercase;
    }

    &-list {
      padding-left: 80px;
    }

    &-item {

    }
  }

  &__footer {
    &-title {
      @include setFont(Roboto, normal, 500, 18px, 28px);
      color: $lightGrayColor;
    }

    &-mail {
      @include setFont(Roboto, normal, 400, 18px, 28px);
      margin-top: 14px;

      &, a {
        color: $grayColor;
      }

      a {
        text-decoration: none;
      }
    }
  }

  @import "media_queries/index";
}