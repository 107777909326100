.loader {
  z-index: 3000;
  position: fixed;

  &, &__body {
    @include setFlex(center, cetner, row);
  }

  &__body {
    width: 79px;
    height: 73px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  &__intro {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border-top: 2px solid $darkYellowColor;
    border-right: 2px solid $darkYellowColor;
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}