@media(max-width: 699px) {
  padding: 0 4px;
  // max-width: 327px;
  margin: 36px auto 0 auto;

  &__container {
    &.select {
      min-height: auto;
      padding-bottom: 100px;
    }

    &.survey {
      min-height: auto;
      padding-bottom: 50px;
    }

    & .share {
      align-items: unset;
    }

    & .pairs__subbuttons {
      display: flex;
      flex-direction: row;
      margin-top: 12px;
      width: 100%;
    }
  }

  &__button {
    &.next {
      font-size: 16px;
      line-height: 19px;
      padding: 10px 20px;
      margin-top: 65px;

      &.select {
        margin: 20px 0 45px 0;
      }

      &.survey {
        margin-top: 10px;
        visibility: visible;
        opacity: 1;

        &.hidden {
          visibility: hidden;
          opacity: 0;
        }
      }
    }

    &.return {
      width: 26px;
      min-width: 26px;
      height: 26px;

      svg {
        width: 5.28px;
        height: 9.63px;
      }
    }

    &.option {
      width: 151px;
      height: 39px;
      margin-top: 41px;
    }

    &.generate {
      @include setFlex(flex-start, center, row);
      @include setFont(Roboto, normal, 400, 12px, 20px);
      width: auto;
      height: auto;
      color: $grayColor;
      background: transparent;
      border: none;
      margin: 0;

      span {
        margin-top: 0;
      }

      svg {
        width: 15px;
        height: 15px;
        margin-right: 5.23px;
      }
    }

    &.anonymization {
      font-size: 16px;
      line-height: 19px;
      margin-top: 48px;
      padding: 12px 24px;
    }
  }

  &__title {
    &.choose {
      @include setFont(Roboto, normal, 500, 18px, 24px);
      margin-bottom: 20px;
    }

    &.anonymize {
      font-size: 18px;
      line-height: 26px;
      margin: 0;
    }
  }

  &__content {
    &.anonymize {
      @include setFlex(center, center, column);
      margin-bottom: 200px;
    }
  }

  &__subtitle {
    &.choose {

      &,
      span {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &.select,
    &.share,
    &.survey {
      margin: 1px 0 0 14px;
    }

    &.select {
      max-width: 264px;
    }

    &.select,
    &.survey {
      font-size: 18px;
      line-height: 26px;
    }
  }

  &__intro {
    &.choose {
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-flow: dense;
      margin-top: 26px;
      flex-direction: column-reverse;
      max-width: none;
      min-width: 100%;
    }

    &.select,
    &.survey {
      align-items: flex-start;
    }

    &.anonymize {
      grid-template-columns: 26px minmax(auto, 292px);
      grid-column-gap: 14px;
      margin-bottom: 28px;
    }

    &-container {
      &.anonymize {
        display: block;
      }
    }
  }

  &__select {
    width: 100%;

    &-intro {
      width: 100%;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 21px;

      @media(max-width:375px) {
        grid-column-gap: 10px;
      }

      &.demo-process {
        display: grid;
        grid-template-columns: minmax(auto, 161px);
        justify-content: center;
        align-items: center;
        margin-bottom: 38px;

        & div.pairs__face {
          display: none;
        }

        & label.pairs__face {
          height: 214px;
        }
      }
    }
  }

  &__face {
    height: 204px;
    border-width: 1.5px;
    padding: 17px;
    // @media(max-width:375px){
    //   height: 200px;
    // }

    &,
    &-input {
      border-radius: 16px;
    }

    &.selected {
      padding: 2.5px;
    }

    &-overlay {
      top: 2.5px;
      left: 2.5px;
      right: 2.5px;
      bottom: 2.5px;
    }

    &.error &-content {
      svg {
        width: 33px;
        height: 34px;
      }
    }

    &-intro,
    &-overlay {
      border-radius: 14px;
    }

    &-content {
      svg {
        width: 16px;
        height: 16px;
      }

      &.active {
        left: -11px;
        bottom: -11px;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    &-icon {
      &_container {
        &.active {
          width: 40px;
          height: 40px;
          border-width: 1.5px;
        }
      }
    }

    &-intro {
      span {
        margin-top: 9.43px;
        font-size: 13px;
        line-height: 15px;
      }
    }
  }

  &__error {
    &.select {
      min-height: 4px;
      margin: 14px 0 18px;
    }
  }

  &__check {
    width: 100%;

    &-title {
      font-size: 16px;
      margin-bottom: 8px;
    }

    &-list {
      grid-row-gap: 10px;
    }

    &-item {
      font-size: 15px;
      line-height: 18px;
    }
  }

  &__process {
    &.select {
      max-width: 100%;
      grid-template-columns: 1fr;
      column-gap: 0;
      margin-top: 34px;
    }

    &.survey {
      max-width: 100%;
      grid-template-columns: 1fr;
      margin-top: 24px;
    }

    &-intro {
      width: 100%;
      max-width: 100%;
      padding-left: 0;

      &.survey {
        width: 100%;
      }
    }

    &-pair {
      grid-template-columns: repeat(2, minmax(auto, 161.38px));
      //column-gap: 6.4px;

      &.pairs__face {
        height: 214.5px;
      }

      &.pairs__face-intro {
        border-radius: 9px;
      }
    }
  }

  &__tab {
    &-face {
      svg {
        width: 163.52px;
        height: 214px;
      }
    }
  }

  &__filter {
    &-list {
      &.select {
        grid-template-columns: repeat(2, 1fr);
        margin-top: 11px;
        grid-column-gap: 13px;
        grid-row-gap: 12px;
      }

      &.survey {
        grid-row-gap: 20px;
        margin: 14px 0 16px 0;
      }
    }

    &-item {
      &.select {
        height: 112px;

        svg {
          width: 14px;
          height: 14px;
        }

        &:nth-child(1) .pairs__filter-content {
          svg:first-child {
            width: 40.28px;
            height: 22.15px;
          }
        }

        &:nth-child(2) .pairs__filter-content {
          svg:first-child {
            width: 59.18px;
            height: 21.46px;
          }
        }

        &:nth-child(3) .pairs__filter-content {
          svg:first-child {
            width: 60.04px;
            height: 22.35px;
          }
        }

        &:nth-child(4) .pairs__filter-content {
          svg:first-child {
            width: 60.65px;
            height: 21.45px;
          }
        }
      }
    }

    &-content {
      &.select {
        span {
          font-size: 15px;
          line-height: 18px;
          margin-top: 9.03px;
        }
      }

      &.survey {
        margin-bottom: 52px;
        font-size: 16px;
        line-height: 19px;
      }
    }

    &-label {
      &.survey {
        font-size: 16px;
        line-height: 19px;
      }
    }

    &-subtitle {
      &.survey {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &-description {
      &.survey {
        width: 100%;
        max-width: 327px;
        min-height: 76px;
        margin-top: 8px;
        padding: 8px 25px 8px 12px;

        &,
        &::placeholder {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    &-comment {
      &.survey {
        width: 100%;
        max-width: 241px;
        margin-left: 10px;
      }
    }
  }

  &__selected {
    margin-top: 9px;

    &-title {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 4px;
    }

    &-subtitle {
      font-size: 14px;
      line-height: 20px;
    }

    &-description {
      font-size: 14px;
      line-height: 24px;
      margin-top: 18px;
    }
  }

  &__footer {
    @include setFlex(center, center, column);
    position: relative;

    &-body {
      display: grid;
      grid-template-columns: repeat(2, minmax(80px, 110px));
      column-gap: 12px;

      & .pairs__face {
        height: 146px;
        border-radius: 12.1418px;

        &.selected {
          padding: 2.66px;
        }

        &-intro,
        &-overlay {
          border-radius: 9px;
        }
      }
    }
  }

  &__slider {
    width: 100%;
    padding: 0 4px;
    position: relative;

    &-container {
      border-radius: 100%;
      padding: 1.5px;
      position: absolute;
      right: -5px;
      bottom: -10px;
    }

    &-footer {
      display: grid;
      grid-template-columns: 1fr 36px 1fr;
      column-gap: 56px;
      align-items: center;
    }

    &-pagination {
      display: grid;
      grid-template-columns: repeat(3, 10px);
      column-gap: 6px;
    }

    &-button {
      &.zoom {
        @include setFlex(center, center, row);
        width: 38px;
        height: 38px;
        border: 1.5px solid $grayColor;
        background-color: $webBgColor;
        border-radius: 100%;
        position: absolute;
        right: -4px;
        bottom: 28px;
        z-index: 1;

        svg {
          width: 17.76px;
          height: 17.76px;
        }
      }

      &.generate {
        @include setFlex(flex-start, center, row);
        @include setFont(Roboto, normal, 400, 12px, 20px);
        color: $grayColor;
        background-color: transparent;

        svg {
          width: 15px;
          height: 15px;
          margin-right: 5.23px;
        }
      }

      &.pagination {
        height: 10px;
        background-color: #3B3B43;
        border: 1px solid $grayColor;
        border-radius: 100%;
        transition: .3s linear;

        &.active {
          background-color: #BD9D2D;
          border-color: #BD9D2D;
        }
      }
    }

    &-list {
      width: 100%;
    }

    &-slide {
      width: 100%;
    }

    &-item {
      width: 100%;
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, minmax(120px, 1fr));
      column-gap: 5px;
      padding-bottom: 16px;

      & .pairs__face {
        height: 214px;
        border-radius: 12.1418px;
        padding: 4.5px;

        &-intro,
        &-overlay {
          border-radius: 9px;
        }
      }
    }
  }

  &__share {
    &.share {
      max-width: 100%;
      grid-template-columns: 1fr;
      column-gap: 0;
      row-gap: 18px;
      margin: 22px 0 0 0;
    }

    &-title {
      font-size: 22px;
      line-height: 26px;
    }

    &-subtitle {
      font-size: 16px;
      line-height: 22px;
      margin: 8px 0 12px 0;
    }

    &-description {

      &,
      a {
        font-size: 15px;
        line-height: 24px;
      }

      a {
        text-decoration: none;
        color: $grayColor;
      }
    }

    &-list {
      grid-template-columns: repeat(auto-fit, 44px);
      grid-column-gap: 26px;
      justify-content: center;
      margin-top: 24px;
    }

    .react-share__ShareButton {
      &:nth-child(1) .pairs__share-item {
        svg {
          width: 11.83px;
          height: 22.41px;
        }
      }

      &:nth-child(2) .pairs__share-item {
        svg {
          width: 22.55px;
          height: 22.62px;
          position: relative;
          top: -1px;
          left: 1px;
        }
      }
    }

    &-item {
      height: 44px;
      border-width: 2px;

      svg {
        width: 22.26px;
        height: 22.26px;
      }
    }

    &-result {
      margin-bottom: 22px;


      & .video {
        height: 243px;
        max-width: 297px;
        min-width: unset;

        &-modal {
          display: flex;
          justify-content: center;
        }

        &__loading {
          width: 100%;
        }

        &__title-logo {
          height: 18px;
        }

        & .pairs__share-subtitle {
          font-size: 14px;
        }

        & .videoTimeline {
          bottom: 15px;
          padding: 0;
          margin-bottom: 2px;
        }
        @media (max-width:370px) {
          &__loading {
            padding: 0 10px;

            &-container {
              width: 100px;
              height: 70px;

              & .video__loading-logo {
                width: 40%;
                bottom: -10%;
                left: 60%;
              }

              & .video__loading-gear {
                width: 35%;
                bottom: -13%;
                left: -4%;
              }
            }

            & .pairs__share-subtitle {
              margin: 4px 0 6px 0;
              line-height: 18px;
              font-size: 13px;
              font-weight: 400;
            }

            & .pairs__share-description {
              font-size: 11px;
              line-height: 15px;
            }
          }
        }
      }
    }
  }

  & .swiper {
    width: 100%;
  }
}