@media(max-width: 699px) {
  &__label {
    @include setFlex(flex-start, flex-start, row);

    & > span::before {
      margin-right: 10px;
    }
  }
  &__title {
    @include setFont(Roboto, normal, normal, 13px, 24px);

    a {
      margin: 0;
    }
  }
}