@media(max-width: 699px) {
  &__button {
    &.exit {
      top: 16px;
      left: 16px;

      svg {
        width: 13.1px;
        height: 13.1px;
      }
    }

    &.save {
      margin-top: 60px;
    }

    &.login {
      margin-top: 53px;
    }
  }

  &__body {
    height: 691px;
    border-radius: 24px;
  }

  &__intro {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 48px 30px 17px 30px;
  }

  &__title {
    &.input {
      margin: 80px 0 50px 0;
    }

    &.verification, &.feedback {
      margin: 60px 0 24px 0;

      svg {
        margin-left: 8px;
      }
    }

    &.create {
      @include setFont(Roboto, normal, 500, 18px, 24px);
      margin: 30px 0 20px 0;
    }
  }
  &__content {
    background-image: url("../../../../assets/img/sign-in__bg-375.svg");
    background-size: cover;
  }
  &__subtitle {
    @include setFont(Roboto, normal, 400, 15px, 24px);

    &.verification {
      margin-bottom: 8px;
    }

    &.create {
      &.first {
        @include setFont(Roboto, normal, 400, 15px, 24px);
        margin-bottom: 20px;
      }
    }
  }

  &__verification {
    margin: 0 0 8px 0;
  }

  &__description {
    &.create {
      @include setFont(Roboto, normal, 400, 13px, 24px);
      width: 100%;
      margin: 2px 0 14px 0;
    }
  }

  &__label {
    &.input {
      margin: 0 0 46px 0;

      &:nth-child(2) {
        & .MuiFormHelperText-root {
          margin: 36px 0 0 0;
        }
      }
    }

    &.create {
      margin: 0 0 44px 0;

      &:nth-child(2) {
        margin-bottom: 0;
      }

      & .MuiFormHelperText-root {
        @include setFont(Roboto, normal, 500, 13px, 16px);
        margin-top: 8px;
      }
    }
  }

  &__forgot {
    button {
      @include setFont(Roboto, normal, normal, 13px, 24px);

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__error {
    @include setFont(Roboto, normal, 500, 13px, 18px);

    &.input {
      margin: 6px 0 26px 0;
    }

    &.verification {
      margin: 8px 0 16px 0;
      height: 16px;
    }
  }

  &__footer {
    @include setFont(Roboto, normal, normal, 13px, 24px);
    margin-top: 140px;
  }

  &__code {
    grid-template-columns: repeat(auto-fit, 37px);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    input {
      @include setFont(Roboto, normal, 400, 20px, 24px);
      width: 37px;
      height: 46px;
      padding-left: 13px;
      border-radius: 8px;
      border: 1.5px solid transparent;
      background-color: #3B3B43;
      color: #FFFFFF;
      transition: .3s linear;

      &:focus {
        border: 1.5px solid #858585;
      }
    }
  }
}