@media(min-width: 2559px) {
    .sign-in {
        &__body {
            width: 1237px;
            height: 874px;
        }

        &__button {
            &.exit {
                top: 29px;
                left: 29px;

                & svg {
                    width: 20px;
                    height: 20px;
                }
            }

            &.login {
                margin-top: 104px;
            }
        }

        &__content {
            & .sign-up__logo {
                width: 260px;
                height: 62px;
            }
        }

        &__intro {
            width: 564px;
            margin: 82px 0 55px 177px;
            padding: unset;

            & .sign-in__title input {
                font-size: 30px;
                margin: 43px 0 43px 0;
            }

            & .sign-in__title.input {
                margin: 43px 0 43px 0;
            }

        }

        &__footer {
            font-size: 20px;
            line-height: 33px;

            & button {
                font-size: 20px;
                line-height: 33px;
            }
        }

        &__label.input {
            &:nth-child(2) {
                margin-bottom: 0;
              }
            & input {
                font-size: 22px;
                line-height: 33px;
            }
            &.input {
                margin: 0 0 67px 0;
          
                &:nth-child(2) {
                  margin-bottom: 0;
                }
              }
        }

        &__forgot {
            margin-top: 7px;

            & button {
                font-size: 18px;
                line-height: 24px;
            }
        }


    }

}