.footer {
  @include setFlex(space-between, center, row);
  height: 55px;
  padding: 19px 54px 20px 64px;
  border-top: 1px solid #43434B;

  &__item {
    @include setFlex(flex-start, center, row);
  }

  &__link, &__rights {
    @include setFont(Roboto, normal, 400, 15px, 21px);
    color: #7B7B7B;
    text-decoration: none;
  }

  &__divider {
    height: 18px;
    margin: 0 10.03px;
    border-right: 0.5px solid #646464;
    position: relative;
    top: -1px;
  }

  @import "media_queries/index";
}