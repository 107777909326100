// demo

@media(min-width:2559px) {
    &__title {
        font-size: 36px;
        line-height: 51px;
        margin: 41px 0 47px 0;
    }

    &__subtitle {
        font-size: 32px;
        line-height: 48px;
    }

    &__intro {
        &.select {}
    }

    &__content {
        &.anonymize {
            grid-template-columns: minmax(407px, 408px) 407px;
            grid-column-gap: 81px;
            align-items: center;
        }

        &.complete {
            grid-template-columns: 410px 509px;
            grid-column-gap: 531px;
        }
    }

    &-modal {
        &__body {
            width: 1054px;
            min-height: 982px;
        }

        &__media {
            & img {
                width: 572px;
                height: 752px;
            }
        }

        &__content {
            padding: 60px 0 40px 0;

            & video {
                width: 572px;
                height: 752px;
            }
        }

        &__footer {
            max-width: 572px;
        }

        &__button {
            &.exit {
                & svg {
                    width: 23px;
                    height: 23px;
                }
            }

            &.use {
                width: 193px;
                height: 55px;
                font-size: 28px;
            }

            &.retake {
                font-size: 24px;
                line-height: 28px;

                & svg {
                    width: 26px;
                    height: 17px;
                }
            }
        }
    }

    &__photo {
        &-img {
            height: 544px;

        }

        &-intro {
            margin-top: 18px;
            margin-bottom: 41px;
        }
    }

    &__label {
        &.anonymize {
            height: 130px;
            font-size: 28px;
            line-height: 33px;

            &:last-child {
                margin-top: 77px;
            }


            & svg {
                width: 41px;
                height: 41px;
            }
        }
    }

    &__original {
        &-title {
            font-size: 32px;
            line-height: 34px;
        }

        &-item {
            width: 421px;
            height: unset;
        }

        &-container {
            margin-top: 27px;
            margin-bottom: 37px;
        }

    }

    &__pagination {
        padding: 0 27px;

        &-item {
            height: 108px;
        }

        &-list {
            grid-template-columns: repeat(3, minmax(auto, 86px));
            grid-column-gap: 20px;
        }
    }


    &__switch {
        &-label {
            font-size: 26px;
            line-height: 30px;
        }
    }

    &__button {
        &.anonymize {
            width: 258px;
            height: 78px;
            font-size: 32px !important;
            line-height: 39px;
        }

        &.generate {
            & svg {
                width: 26px;
                height: 26px;
            }

            & span {
                font-size: 21px;
                line-height: 25px;
            }
        }
    }

    &__share {
        &.pairs__share {
            margin-top: 80px;
            grid-template-columns: minmax(795px, 800px) auto;
        }
    }

    // from pairs component 
    & .pairs {
        &__button {
            &.anonymization {
                width: 479px;
                height: 75px;
                font-size: 32px;
                line-height: 39px;
            }
        }

        &__intro {
            &.choose {
                max-width: none;
                justify-content: unset;
            }
        }

        &__share {
            &-item {
                height: 74px;
            }

            &-list {
                grid-template-columns: repeat(auto-fit, 74px);
            }

            &-title {
                font-size: 46px;
                line-height: 35px;
            }

            &-subtitle {
                font-size: 32px;
                line-height: 35px;
                margin-top: 35px;
                margin-bottom: 12px;

                &.last {
                    margin-top: 46px;
                    margin-bottom: 25px;
                }
            }

            &-description {
                font-size: 32px;
                line-height: 50px;
            }
        }

        &__check {
            &-title {
                font-size: 28px;
                line-height: 32px;
                margin-bottom: 28px;
            }

            &-list {
                grid-row-gap: 21px;
                margin-right: 208px;
            }

            &-item {
                font-size: 28px;
                line-height: 32px;
                font-weight: 400;

                & .check-point {
                    width: 29px;
                    height: 29px;

                    & svg {
                        width: 13px;
                        height: 13px;
                    }
                }
            }
        }

        &__face {
            height: 560px;

            &-content {
                & span {
                    font-size: 28px;
                    line-height: 33px;
                }
            }
        }

        &__select {
            &-intro {
                &.demo-process {
                    grid-template-columns: repeat(2, minmax(405px, 407px));
                    grid-column-gap: 71px;
                }
            }
        }
    }
}