@media(max-width: 1025px) {
  height: 28px;
  padding: 0;
  border: none;
  flex-wrap: wrap;
  &__link {
    font-size: 15px;
    line-height: 28px;
  }
  &__divider {
    margin: 0 14px;
  }
}