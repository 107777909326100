.privacy {
  .mb-class {
    margin-bottom: 8px;
  }

  .pl-class-list {
    padding-left: 28px;

    p {
      padding-left: 30px;
    }
  }

  @include setFlex(center, center, row);
  width: 100%;
  margin-top: 74px;

  &__container {
    max-width: 662px;
  }

  &__title {
    @include setFont(Roboto, normal, 400, 28px, 24px);
    color: $lightGrayColor;
    margin-bottom: 19px;
  }

  &__date {
    @include setFont(Roboto, normal, 400, 15px, 24px);
    color: $grayColor;
  }

  &__list {
    display: grid;
    row-gap: 42px;
    margin: 42px 0;
  }

  &__article {
    display: grid;
    row-gap: 14px;
    word-break: break-word;

    &-container {
      display: grid;
      row-gap: 22px;
      padding-left: 26px;

      &_content {
        display: grid;
        row-gap: 14px;
      }

      &_title {
        @include setFont(Roboto, normal, 500, 18px, 28px);
        color: $lightGrayColor;
        text-align: justify;
      }

      &_list {
        list-style-type: disc;
      }

      &_item {
        @include setFont(Roboto, normal, 400, 18px, 28px);
        color: $grayColor;
        text-align: justify;
      }
    }

    &-title {
      @include setFont(Roboto, normal, 500, 20px, 24px);
      color: $lightGrayColor;
    }

    &-subtitle, &-description {
      text-align: justify;

      &, a {
        color: $grayColor;
      }
    }

    &-subtitle {
      @include setFont(Roboto, normal, 400, 18px, 28px);

      strong {
        color: $lightGrayColor;
      }
    }

    &-description {
      @include setFont(Roboto, normal, 400, 16px, 26px);
      text-transform: uppercase;
    }

    strong.privacy__article-description, strong.privacy__article-subtitle {
      font-weight: bold;
    }

    &-list {
      padding-left: 80px;

      &.links-list {
        padding-left: 26px;
      }

      &.disc {
        list-style-type: disc;
      }

      &.lower {
        list-style-type: lower-alpha;
        padding-left: 52px;
      }
    }

    &-item {
      @include setFont(Roboto, normal, 400, 18px, 28px);

      &, a {
        color: $grayColor;
      }

      a {
        text-decoration: none;
      }
    }
  }

  &__footer {
    &-title {
      @include setFont(Roboto, normal, 500, 18px, 28px);
      color: $lightGrayColor;
    }

    &-mail {
      @include setFont(Roboto, normal, 400, 18px, 28px);
      margin-top: 14px;

      &, a {
        color: $grayColor;
      }

      a {
        text-decoration: none;
      }
    }
  }

  @import "media_queries/index";
}