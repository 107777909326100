@media(max-width: 1025px) {

  &__button {
    &.return {
      width: 24px;
      height: 24px;

      svg {
        width: 6.09px;
        height: 11.11px;
      }
    }

    &.anonymization {
      margin-top: 84.68px;
      padding: 7.11px 17.07px;
      font-size: 14px;
      line-height: 17px;
    }

    &.next {
      &.choose, &.select {
        width: 113px;
        height: 39px;
        font-size: 16px;
        line-height: 19px;

        svg {
          width: 21.19px;
          height: 12px;
          margin-left: 5.89px;
        }
      }

      &.select {
        margin: 25.65px 0 85px 0;
      }

      &.survey {
        font-size: 16px;
        line-height: 19px;
        margin: 30px 0 80px 0;
      }
    }

    &.option {
      font-size: 16px;
      line-height: 19px;
    }

    &.generate {
      width: 103.98px;
      height: 71.18px;
      font-size: 14px;
      line-height: 13px;

      svg {
        width: 16.8px;
        height: 16.8px;
      }

      span {
        margin-top: 7.68px;
      }
    }
  }
  &__title {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 24.4px;

    &.anonymize {
      font-size: 18px;
      line-height: 15px;
      margin: 4px 0 17.59px 0;
    }
  }
  &__subtitle {
    font-size: 16px;
    line-height: 22px;

    &.select, &.survey {
      font-size: 18px;
      line-height: 15px;
    }
  }
  &__intro {
    &.choose {
      grid-column-gap: 61px;
      grid-template-columns: 213px minmax(250px, 394.62px);
      margin-top: 52.6px;
    }

    &.anonymize {
      grid-template-columns: 24px minmax(auto, 862.21px);
      grid-column-gap: 21px;
    }

    &-container {
      &.anonymize {
        column-gap: 103.98px;

        & .pairs__select-intro {
          column-gap: 12.8px;
        }
      }
    }
  }
  &__check {
    &-title {
      font-size: 14px;
      line-height: 22px;
    }

    &-item {
      font-size: 14px;
      line-height: 22px;

      span {
        margin-left: 13.59px;
      }
    }
  }
  &__select-intro {
    grid-template-columns: repeat(2, minmax(auto, 183.16px));
    grid-column-gap: 28px;
  }
  &__face {
    height: 244.75px;
    padding: 20.63px 20.43px;
    border-width: 1.2px;

    &, &-input {
      border-radius: 15.4532px;
    }

    &-intro, &-overlay {
      border-radius: 14.1618px;
    }

    &-content {
      svg {
        width: 19.02px;
        height: 19.02px;
      }

      &.active {
        left: -14px;
        bottom: -14px;
      }
    }

    &-icon {
      &_content {
        svg {
          width: 35.87px;
          height: 37.59px;
        }

        &.active {
          svg {
            width: 20.83px;
            height: 21px;
          }
        }
      }

      &_container {
        &.active {
          width: 35px;
          height: 35px;
          padding: 1.26px;
        }
      }
    }

    &-intro {
      span {
        font-size: 14px;
        line-height: 16px;
        margin-top: 11.28px;
      }
    }
  }
  &__share {
    max-width: calc(100% - 102px);
    grid-template-columns: minmax(350px, 429.75px) minmax(auto, 571px);
    grid-column-gap: 74.25px;
    margin: 31.75px 0 0 0;

    &-title {
      font-size: 20px;
      line-height: 14px;
      margin-top: 0;
    }

    &-content &-description {
      max-width: 380px;
    }

    &-subtitle {
      font-size: 15px;
      line-height: 14px;
      margin: 20.29px 0 6.71px 0;

      &.last {
        margin: 51px 0 10.96px 0;
      }
    }

    &-description {
      &, a {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &-list {
      grid-template-columns: repeat(auto-fit, 36px);
      grid-column-gap: 12px;
    }

    &-item {
      height: 36px;
      border-width: 1.5px;

      svg {
        width: 18.72px;
        height: 18.72px;
      }
    }

    .react-share__ShareButton {
      &:nth-child(1) .pairs__share-item {
        svg {
          width: 10.08px;
          height: 18.72px;
        }
      }

      &:nth-child(2) .pairs__share-item {
        svg {
          width: 18.72px;
          height: 18.72px;
        }
      }
    }

    &-content {
      & > * {
        max-width: 571px;
      }
    }
  }
  &__error {
    margin: 5.28px 0 14px 0;
  }
  &__process {
    &.select {
      grid-template-columns: minmax(230px, 311px) minmax(300px, 387.32px);
      column-gap: 119px;
      max-width: calc(100% - 104px);
      margin-top: 30.74px;
    }
  }
  &__tab {
    &-face {
      border-width: 1.94px;
      box-shadow: 0 3.86336px 10.3023px rgba(0, 0, 0, 0.05);
      position: relative;
      top: -1px;

      svg {
        width: 152.6px;
        height: 212.72px;
      }
    }
  }
  &-title {
    font-size: 15px;
    line-height: 14px;
    margin-bottom: 8px;
  }
  &__filter {
    &-list {
      &.select {
        grid-template-columns: repeat(2, minmax(auto, 144.53px));
        column-gap: 22.58px;
        row-gap: 19.36px;
        margin-top: 17.83px;
      }

      &.survey {
        margin: 11.9px 0 14px 0;
        row-gap: 14px;
      }
    }

    &-item {
      &.select {
        height: 103.24px;
        border-width: 0.645231px;
        box-shadow: 0 25.8092px 19.3569px -19.3569px rgba(0, 0, 0, 0.05);
        border-radius: 2.58092px;

        svg {
          width: 14.2px;
          height: 14.2px;
        }

        &:nth-child(1) .pairs__filter-content {
          svg:first-child {
            width: 31.62px;
            height: 17.27px;
          }
        }

        &:nth-child(2) .pairs__filter-content {
          svg:first-child {
            width: 47.1px;
            height: 18.71px;
          }
        }

        &:nth-child(3) .pairs__filter-content {
          svg:first-child {
            width: 47.1px;
            height: 18.71px;
          }
        }

        &:nth-child(4) .pairs__filter-content {
          svg:first-child {
            width: 47.1px;
            height: 18.71px;
          }
        }
      }
    }

    &-content {
      &.select {
        span {
          font-size: 12.8712px;
          line-height: 15px;
          margin-top: 6.45px;
        }
      }

      &.survey {
        margin-bottom: 47.06px;
      }
    }

    &-subtitle {
      &.survey {
        font-size: 15px;
        line-height: 18px;
      }
    }

    &-label {
      &.survey {
        font-size: 14px;
        line-height: 24px;

        & > span::before {
          width: 20.24px;
          height: 20.24px;
          border-radius: 3.37394px;
          margin-right: 8.76px;
        }
      }
    }

    &-description {
      &.survey {
        min-height: 69.02px;
        padding: 8px 12px;
        margin-bottom: 5.23px;

        &, &::placeholder {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
  &__selected {
    margin-top: 14.81px;

    &-title {
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 4.65px;
    }

    &-subtitle {
      font-size: 14px;
      line-height: 14px;
    }

    &-description {
      font-size: 13px;
      line-height: 14px;
      margin-top: 19.64px;

      &::before {
        margin-right: 3.21px;
      }
    }
  }
  &__process {
    max-width: calc(100% - 104px);
    margin-top: 33px;

    &.survey {
      grid-template-columns: minmax(150px, 337px) minmax(250px, 387.32px);
      grid-column-gap: 93px;
    }

    &-pair {
      grid-template-columns: repeat(2, minmax(auto, 229px));
      grid-column-gap: 30px;
    }
  }
  &__candidates {
    margin: 25.59px 0 54.09px 0;

    &-list {
      width: 377.72px;
      grid-template-columns: repeat(auto-fit, 121.57px);
      grid-column-gap: 6.4px;
    }

    &-item {
      border-radius: 4.79894px;
    }

    &-content {
      grid-template-columns: repeat(2, 53.59px);
      grid-column-gap: 4.8px;
      padding: 4.8px;
    }

    &-content, &-block, &-img {
      border-radius: 4.50718px;
    }

    &-block {
      border-width: 0.32px;
    }

    &-img {
      height: 67.99px;
      padding: 1px;
    }

    &-option {
      width: 22.4px;
      height: 22.4px;
      font-size: 14px;
      line-height: 16px;
      border-width: 1.59965px;
      box-shadow: 1.28777px 1.28777px 3.8633px rgba(0, 0, 0, 0.3);
      bottom: -7px;
    }
  }
}