.aside {
  min-height: 100vh;
  padding: 30px 19px 59px 19px;
  border-right: 1px solid #43434B;
  
  &__intro {
    position:fixed; 
    @include setFlex(center, center, column);
    height: 80%;

    &.active {
      justify-content: space-between;
    }
  }

  &__profile {
    @include setFont(Roboto, normal, 500, 24px, 34px);
    @include setFlex(center, center, row);
    width: 60px;
    height: 60px;
    min-height: 60px;
    min-width: 60px;
    color: #858798;
    background: #33333C;
    border: 2px solid #43434B;
    border-radius: 100%;
    text-decoration: none;
  }

  &__point {
    &-list {
      display: grid;
      grid-row-gap: 20px;
    }

    &-item {
      @include setFlex(center, center, row);
      width: 62px;
      height: 58px;
      border-radius: 13px;
      background-color: transparent;

      &, & * {
        transition: .3s linear;
      }

      &:nth-child(2) {
        svg {
          position: relative;
          left: 1px;
        }
      }

      &.selected {
        background-color: #33333C;

        path {
          fill: $darkYellowColor;

          &:nth-last-child(1), &:nth-last-child(2) {
            stroke: $darkYellowColor;
          }
        }
      }

      &.disabled {
        pointer-events: none;

        path {
          fill: #3B3B43;

          &:nth-last-child(1), &:nth-last-child(2) {
            stroke: #3B3B43;
          }
        }
      }
    }
  }

  &__exit {
    background-color: transparent;
  }

  @import "media_queries/index";
}