@media(max-width: 1440px) {
  &__button {
    &.subscribe {
      font-size: 20px;
      line-height: 23px;
    }

    &.login {
      margin-left: 68px;
    }
  }
}