@media(max-width: 1025px) {
  &__button {
    &.exit {
      top: 21.68px;
      left: 21.68px;

      svg {
        width: 11.62px;
        height: 11.62px;
      }
    }

    &.login {
      margin-top: 50.53px;
    }

    &.send {
      font-size: 12px;
      line-height: 19px;
    }

    &.save {
      font-size: 16px;
      line-height: 19px;
      margin: 44.31px 0 29.42px 0;
    }
  }

  &__body {
    width: 700px;
    min-height: 494px;
    border-radius: 18.5841px;
    box-shadow: 0 13.251px 28.7105px rgba(0, 0, 0, 0.25);
  }

  &__content {
    width: 100%;
    height: 100%;
  }

  &__intro {
    width: 422px;
    padding: 46px 0 0 100.66px;
  }

  &__title {
    &.input {
      margin: 24.78px 0 34.07px 0;
    }

    &.verification, &.feedback {
      @include setFlex(flex-start, center, row);
      margin: 58.08px 0 15px 0;

      svg {
        margin-left: 6.84px;
      }
    }

    &.create {
      margin: 24.78px 0 17.04px 0;
    }
  }

  &__subtitle {
    font-size: 13px;
    line-height: 19px;

    &.verification {
      &.first {
        margin-bottom: 21px;
      }
    }

    &.feedback {
      width: 428px;
      max-width: 104%;
    }

    &.create {
      &.first {
        margin-bottom: 10.51px;
        width: calc(100% + 20px);
      }
    }
  }

  &__verification {
    margin: 5.86px 0;
  }

  &__description {
    &.verification, &.feedback {
      @include setFont(Roboto, normal, 400, 14px, 24px);
    }

    &.verification {
      margin: 44px 0 15px 0;
    }

    &.feedback {
      margin: 80px 0 16px 0;
    }

    &.create {
      font-size: 13px;
      line-height: 17px;
      margin: 3px 0 10.54px 0;
    }
  }

  &__feedback {
    &.feedback {
      span {
        &:first-child {
          margin-right: 8px;
        }
      }
    }
  }

  &__label {
    &.input {
      margin: 0 0 37.17px 0;

      &:nth-child(2) {
        margin-bottom: 0;
      }
    }

    &.create {
      margin: 0 0 32.52px 0;

      &:nth-child(2) {
        margin-bottom: 0;
      }

      .MuiFormHelperText-root {
        margin-top: 8px;
      }
    }
  }

  &__forgot {
    margin-top: 3.82px;

    button {
      font-size: 11px;
      line-height: 19px;
    }
  }

  &__error {
    font-size: 12px;
    line-height: 19px;

    &.input {
      height: 24px;
      margin: 8px 0 30px 0;
    }

    &.verification {
      height: 24px;
      margin: 0;
    }
  }

  &__footer {
    &, * {
      font-size: 11px;
      line-height: 19px;
    }

    margin-top: 56.94px;

    button {
      margin-left: 6.41px;
    }
  }

  &__code {
    grid-template-columns: repeat(6, 40.27px);
    grid-column-gap: 9.29px;

    input {
      height: 40.27px;
      font-size: 17px;
      line-height: 19px;
      padding-left: 14.41px;
      border-width: 1.1615px;
      border-radius: 6.19469px;
    }
  }
}