.modalChoose {
    min-width: 100vw;
    min-height: 100vh;
    background-color: #0B0B0CB3;
    position: fixed;
    top: 0;
    left: 0;
    transform: rotateX(50%);
    transform: rotateY(50%);
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;

    &-wrapper {
        position: absolute;
        min-width: 733px;
        height: 786px;
        max-height: 85%;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        border-radius: 18px;
        background-color: #3B3B43;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__icon{
            margin-bottom: 20px;
        }

        &__title {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 24px;
            margin-bottom: 22px;
        }

        &__subtitle {
            width: 60%;
            text-align: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 26px;
            color: $lightGrayColor;
        }
    }
    &__button-container {
        position: relative;
        width: 285px;
        min-width:281px;
        height: 91px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
        margin-bottom: 29px;
        overflow: hidden;
        border: none;
        outline: none;
        border: 2px solid #DDDDDD;
        border-radius: 8px;
        background: linear-gradient(126.6deg, rgba(255, 255, 255, 0.096) 28.69%, rgba(255, 255, 255, 0) 100%);
        cursor: pointer;
    }

    &__button {
        width: 281px;
        height: 87px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2px;
        background: linear-gradient(126.6deg, #4E4E55 28.69%, #3B3B43 100%);
        opacity: 100%;
        border-radius: 8px;
        overflow: hidden;
        color: $grayColor;

        & p{
            font-family: Roboto, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: #DDDDDD;
        }

        &-close {
            position: absolute;
            top: 30px;
            left: 30px;
            background-color: transparent;

            & svg {
                fill: #DDDDDD;
                width: 13px;
                height: 13px;
            }
        }
        & img{
            width:  30px;
            height: 23px;
            fill: $grayColor;
            margin-bottom: 10px;
        }
    }
}