@media(max-width: 1025px) {
  margin-bottom: 60px;
  &__button {
    &.return {
      width: 24px;
      height: 24px;

      svg {
        width: 4.88px;
        height: 8.89px;
      }
    }

    &.generate {
      span {
        font-size: 9.57791px;
        line-height: 11px;
      }

      svg {
        width: 12.86px;
        height: 12.86px;
        margin-bottom: 3.21px;
      }
    }

    &.select {
      margin-top: 45.76px;
    }
  }
  &__title {
    font-size: 18px;
    line-height: 22px;
    margin: 30px 0 24.4px 0;
  }
  &__subtitle {
    font-size: 16px;
    line-height: 22px;

    &.anonymize, &.complete, &.share {
      margin-left: 21px;
    }

    &.complete, &.share {
      font-size: 18px;
      line-height: 15px;
    }
  }
  &-modal {
    &__body {
      width: 535px;
      max-height: 498.92px;
    }

    &__content {
      padding: 21.8px 0;
    }

    &__button {
      &.exit {
        top: 22.48px;
        left: 22.48px;

        svg {
          width: 10.22px;
          height: 10.22px;
        }
      }

      &.photo {
        width: 58.59px;
        height: 58.59px;
        top: -25px;

        svg {
          width: 34.06px;
          height: 26.81px;
        }
      }

      &.use {
        width: 94.71px;
        height: 26.21px;
        font-size: 14px;
        line-height: 16px;
      }

      &.retake {
        font-size: 13px;
        line-height: 15px;
        box-shadow: 0 6.80662px 8.8486px -2.72265px rgba(0, 0, 0, 0.15);
        border-radius: 4.08397px;

        svg {
          width: 13.68px;
          height: 8.73px;
        }

        span {
          margin-left: 2.7px;
        }
      }
    }

    &__title {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 10.5px;
    }

    &__content {
      video, img {
        width: 291.32px;
        height: 382.53px;
        border-radius: 9.05668px;
      }
    }

    &__footer {
      max-width: 291.32px;
      margin-top: 16.46px;
    }
  }
  &__intro {
    &.anonymize {
      margin-top: 52.6px;
    }

    &.complete {
      margin-top: 43px;
    }
  }
  &__face {
    &-content {
      &.anonymize {
        svg {
          width: 20.25px;
          height: 20.25px;
          margin-bottom: 3.85px;
        }
      }
    }
  }
  &__label {
    &.anonymize {
      height: 58.43px;
      font-size: 13px;
      line-height: 15px;
      border-radius: 5.13684px;
      border-width: 1.28px;
    }
  }
  &__photo {
    &-intro {
      border-width: 1.19974px;
      padding: 4px;
      border-radius: 15.4532px;

      &.complete {
        margin: 11.28px 0 31.41px 0;
      }
    }

    &-img, &-overlay {
      border-radius: 14.1618px;
    }

    &-img {
      height: 236.75px;
    }
  }
  &__error {
    font-size: 13px;
    margin: 13.25px 0 38px 0;
  }
  &__content {
    &.anonymize {
      grid-template-columns: minmax(150px, 183.16px) 183px;
      grid-column-gap: 39.84px;
    }

    &.complete {
      grid-template-columns: 183.16px 251.55px;
      grid-column-gap: 176.22px;
      margin-top: 43px;
    }
  }
  &__switch {
    &-label {
      font-size: 12.7705px;
      line-height: 15px;
    }
  }
  &__original {
    &-title {
      font-size: 12.7705px;
      line-height: 15px;
    }

    &-item {
      width: 183.16px;
      height: 244.21px;
      border-width: 1.29px;
      padding: 3.86px;
      border-radius: 18.0014px;
    }

    &-img {
      border-radius: 14.1439px;
    }

    &-container {
      margin: 9px 0 10.38px 0;
    }

    &-arrow {
      svg {
        width: 10.93px;
        height: 23.14px;
      }
    }
  }
  &__pagination {
    padding: 0 40px;

    & .demo__original-img {
      border-radius: 3.88033px;
    }

    &-list {
      grid-template-columns: repeat(3, minmax(auto, 38.57px));
      grid-column-gap: 1.28px;
    }

    &-item {
      height: 48.66px;
      padding: 3.86px;
    }
  }
  &__share {
    max-width: calc(100% - 94px);
    grid-template-columns: minmax(250px, 368px) minmax(auto, 402.19px);
    grid-column-gap: 47px;
    margin-top: 38px;
  }
}