@media(min-width: 2559px) {
    .app {
        grid-template-columns: 133px auto;
    }

    // <===aside menu===> 
    .aside {
        width: 134px;
        padding: 30px 26px 59px 26px;
        z-index: 100;

        &__intro {
            height: 65%;
        }

        &__point-list {
            grid-row-gap: 30px;

            & svg {
                width: 30px;
                height: 30px;
            }
        }

        &__profile {
            width: 80px;
            height: 80px;
            margin-top: 19px;
        }

        &__point-item {
            width: 82px;
            height: 77px;
        }
        &__exit{
            & svg{
                width: 30px;
                height: 30px;
            }
        }
    }

    // <===main area===> 
    .wrapper {

        // <==header==> 
        & .header {
            padding: 42px 132px 0 138px;

            &__content {
                & img {
                    width: 280px;
                }

                // navigation buttons 
                & .nav {
                    &__button {
                        &.subscribe {
                            font-size: 27px;
                        }

                        &.login {
                            font-size: 24px;
                            font-weight: 400;
                            width: 102px;
                            height: 50px;
                            margin-left: 91px;
                            border-radius: 8px;

                        }
                    }
                }
            }
        }
        // <==main==>
        & .main {
            padding: 0 50px 0 140px;

            & .private {
                max-width: 2115px;

                &__button.join {
                    bottom: 144px;
                    right: 90px;
                    & svg {
                        width: 268px;
                        height: 268px;
                    }
                }
            }
        }

        // <==footer==> 
        & .footer {
            height: 97px;
            padding-right: 80px;
            &__rights,
            &__link {
                font-size: 20px;
            }

            &__divider {
                height: 24px;
                margin: 0 19px;
            }
        }

    }
    .sign {
        &-title {
          @include setFont(Roboto, normal, 500, 30px, 33px);
        }
        &-button {
            @include setFont(Roboto, normal, 600, 24px, 28px);
            @include setFlex(center, center, row);
            border-radius: 8px;
            padding: 14px 33px;
          }
    }

}