@media(max-width: 1440px) {

    @media(max-height:720px) {
        &__point {
            &-list {
                display: grid;
                grid-row-gap: 8px;
            }
        }
    }
}