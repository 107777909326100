@media(max-width: 1440px) {
  & {
    padding-top: 165.37px;
  }
  &__intro {
    padding: 0;
  }
  &__title {
    font-size: 30px;
    line-height: 27px;
  }
  &__subtitle {
    margin: 22px 0 34px 0;
    font-size: 18px;
    line-height: 30px;
  }
  .link {
    height: 44px;
  }
  &__link {
    &.start {
      font-size: 18px;
      line-height: 22px;
    }

    &.demo-link {
      font-size: 16px;
      line-height: 19px;
      border-width: 1.5px;
    }
  }
  &__footer {
    grid-template-columns: repeat(2, 168px);
  }
}