@media(max-width: 1440px) {
  max-width: 1540px;

  &__subtitle {
    &.input {
      font-size: 20px;
      line-height: 21px;
      margin: 62.37px 0 59.18px 0;
    }
  }

  &__intro {
    &.input {
      justify-content: flex-start;
    }

    &.feedback {
      margin-top: 114.38px;
    }
  }

  &__label {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 247.06px));
    column-gap: 45.88px;
    margin-bottom: 60px;

    &:nth-of-type(4) {
      row-gap: 4.47px;
    }

    &-legend {
      font-size: 16px;
      line-height: 21px;
    }
  }

  &__form {
    margin-right: 257px;
  }

  &__info {
    &.input {
      padding-top: 202px;
    }

    &.feedback {
      padding-top: 256px;
    }

    &-title {
      font-size: 50px;
      line-height: 60px;
    }

    &-subtitle {
      font-size: 31px;
      line-height: 40px;
      margin-bottom: 3.18px;
    }

    svg {
      width: 636.5px;
      height: 705.76px;
      bottom: -332px;
      margin-left: 64px;
    }
  }
}