@media(max-width: 699px) {
  max-width: 335px;
  margin: 0 auto;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 36px;
    margin-bottom: 12px;

    & .portfolio__title {
      margin: 0;
    }

    & span {
      height: 13px;
      width: 1px;
      // background-color: white;
      border: 1px solid $lightGrayColor
    }
  }

  &__subbuttons {
    display: flex;
    flex-direction: row;
    margin-top: 12px;

    &-button {
      width: 80px;
      margin-right: 10px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $darkGrayColor;

      &.active {
        border-bottom: 3px solid $darkYellowColor;
        color: $grayColor;
      }
    }
  }

  &__divider {
    width: 150%;
    margin-left: -20%;
    margin-bottom: 18px;
    border-bottom: 1px solid $darkGrayColor;

  }

  &>* {
    padding: 0 4px;
  }

  &__title {
    font-size: 18px;
    line-height: 24px;
    margin: 36px 0 12px 0;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 24px;
  }

  &__cards {
    @include setFlex(center, center, column);
    padding: 0;
    margin-top: 0;

    &-item {
      flex-direction: column;
      height: 442px;
      max-height: 100%;
      border-width: 1px;
      border-color: #474747;
    }
  }

  &__postcards {
    & .video {
      height: 243px;

      &__react-player {
        width: auto;
        max-width: 293px;
      }

      &__title-logo {
        padding-bottom: 5px;
      }

      & .videoTimeline {
        bottom: 17px;
      }
    }

    &,
    &-intro {
      width: 100%;

    }

    &-intro {
      align-items: flex-start;
      padding: 18px 12px 0 12px;

      @media(max-width:340px) {
        padding: 18px 2px 0 2px;
      }
    }

    &-content {
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 13px;

      &-video {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
      }
    }

    &-delete {
      width: 100%;
      height: auto;
      border-left: none;
      border-top: 1px solid #474747;
      padding: 18px 24px;
      justify-content: space-between;
    }

    &-block {
      height: 107px;
      border-width: 0.53px;
      width: 100%;
    }

    @media(max-width:375px) {
      &-content {
        grid-template-columns: 1fr 1fr;
      }

      &-block {
        height: fit-content;
        width: fit-content;
        max-width: 100%;
      }
    }
  }

  &__details {
    width: 100%;
    max-width: 100%;
    margin-top: 24px;
    margin-right: 0;
    row-gap: 24px;

    &-item {
      width: 100%;
    }

    &-title {
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 4px;
    }

    &-subtitle {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__original {
    width: 100%;
    &-container {
      grid-template-columns: repeat(2, 0.7fr);
      column-gap: 3px;
      padding: 1.5px 2px 9px 2px;
      border-width: 0.53px;
    }

    &-block {
      height: 95px;
    }

    @media(max-width:375px) {
      align-items: unset;
      justify-content: unset;
      grid-row-gap: 0.46999999999999886px;

      &-container {
        grid-template-columns: 1fr 1fr;
      }

      &-block {
        max-height: 100%;
        width: 100%;
      }

    }
  }

  &__share {
    &-list {
      grid-template-columns: 12px repeat(3, 20px);
      grid-column-gap: 32px;
      align-items: center;

      button[aria-label="facebook"] {
        svg {
          width: 12px;
          height: 20px;
        }
      }
    }

    &-item {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__params {
    &-item {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__slider {
    width: 100%;

    &-pagination {
      @include setFlex(space-between, center, row);
      width: 78px;
      margin-top: 35px;
    }

    &-arrow {
      background-color: transparent;

      &,
      svg {
        width: 9px;
        height: 17px;
      }

      &.next {
        transform: rotate(180deg);
      }
    }

    &-index {
      @include setFont(Roboto, normal, 500, 18px, 24px);
      color: #999999;
    }
  }
}