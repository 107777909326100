@media(max-width: 1440px) {
  & {
    margin-top: 64px;
    width: calc(100%);
  }

  &__button {
    &.anonymization {
      margin-top: 100px;
      padding: 7px 18px;
      font-size: 14px;
      line-height: 16px;
      font-family: Roboto;
      font-style: Medium;
      font-weight: 500;
    }

    &.return {
      width: 30px;
      height: 30px;

      svg {
        width: 6.09px;
        height: 11.11px;
      }
    }

    &.next {
      &.choose {
        width: 117px;
        height: 44px;
        font-size: 18px;
        line-height: 22px;
        padding: 0;

        svg {
          width: 21.19px;
          height: 12px;
          margin-left: 10.59px;
        }
      }

      &.select {
        margin: 28px 0 45px 0;
      }

      &.survey {
        margin: 40px 0 45px 0;
      }
    }

    &.generate {
      width: 130px;
      height: 89px;
      font-size: 15px;
      line-height: 16px;
      margin-left: 14px;

      span {
        margin-top: 9px;
      }

      svg {
        width: 21px;
        height: 21px;
      }
    }

    &.option {
      width: 191px;
      height: 42px;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__title {
    &.anonymize {
      font-size: 20px;
      line-height: 19px;
      margin: 5px 0 22px 0;
    }
  }

  &__subtitle {
    font-size: 20px;
    line-height: 19px;
    font-family: Roboto;
    font-style: Medium;
    font-size: 20px;
    line-height: 19.32px;

    &.select,
    &.survey,
    &.share {
      margin-left: 26px;
    }
  }

  &__intro {
    &.choose {
      display: grid;
      grid-column-gap: 95px;
      grid-template-columns: minmax(170px, 228px) minmax(300px, 498px);
      justify-content: unset;
      align-items: unset;
    }

    &.anonymize {
      grid-template-columns: 30px minmax(auto, 1134px);
      grid-column-gap: 26px;
    }

    &-container {
      &.anonymize {
        grid-template-columns: repeat(2, minmax(auto, 474px));
        grid-column-gap: 130px;
      }

    }
  }

  &__check {
    &-title {
      font-size: 15px;
      margin-bottom: 12px;
    }

    &-item {
      font-size: 15px;
    }
  }

  &__select-intro {
    grid-template-columns: repeat(2, minmax(100px, 229px));
    grid-column-gap: 40px;
  }

  &__face {
    height: 306px;
    padding: 25px;
    border-width: 1.5px;

    &-content {
      svg {
        width: 23.76px;
        height: 23.76px;
      }
    }

    &-intro {
      span {
        font-size: 16px;
        line-height: 19px;
        margin-top: 14.09px;
      }
    }

    &-icon {
      &_container {
        &.active {
          width: 48px;
          height: 48px;
          padding: 1.78px;
        }
      }

      &_content {
        svg {
          width: 58.01px;
          height: 58.01px;
        }

        &.active {
          svg {
            width: 28.56px;
            height: 28.8px;
          }
        }
      }
    }
  }

  &__share {
    max-width: calc(100% - 118px);
    grid-template-columns: minmax(350px, 429.75px) minmax(auto, 601px);
    grid-column-gap: 74.72px;
    margin: 31.75px 0 0 0;

    & .wrapper {
      width: auto;
    }

    &-title {
      font-size: 26px;
      line-height: 32px;
      margin-top: 6.25px;
    }

    &-subtitle {
      font-size: 18px;
      line-height: 19px;
      margin: 24px 0 8.75px 0;

      &.last {
        margin: 34px 0 14px 0;
      }
    }

    &-description {

      &,
      a {
        font-size: 15px;
        line-height: 21px;
      }
    }

    &-list {
      grid-template-columns: repeat(auto-fit, 42px);
      grid-column-gap: 14px;
    }

    &-item {
      height: 42px;
      border-width: 1.5px;

      svg {
        width: 21.84px;
        height: 21.84px;
      }
    }

    .react-share__ShareButton {
      &:nth-child(1) .pairs__share-item {
        svg {
          width: 11.76px;
          height: 21.84px;
        }
      }

      &:nth-child(2) .pairs__share-item {
        svg {
          width: 21.84px;
          height: 21.84px;
        }
      }
    }

    &-content {
      &>* {
        max-width: 601px;
      }
    }
  }

  &__process {
    grid-template-columns: minmax(280px, 387.79px) minmax(350px, 488px);
    column-gap: 202px;
    max-width: calc(100% - 118px);
    margin-top: 46px;

    &.survey {
      grid-template-columns: minmax(200px, 449px) minmax(300px, 488px);
      column-gap: 141px;
    }

    &-intro {
      padding-left: 0;
    }

    &-title {
      font-size: 18px;
      line-height: 19px;
      margin-bottom: 10px;
    }
  }

  &__filter {
    &-comment {
      &.survey {
        width: auto;
        max-width: calc(100% - 20px);
      }
    }

    &-list {
      &.select {
        grid-template-columns: repeat(2, minmax(144.53px, 179px));
        column-gap: 28.07px;
        row-gap: 24.06px;
        margin-top: 23px
      }

      &.survey {
        margin: 21px 0 16px 0;
      }
    }

    &-item {
      &.select {
        height: 128.33px;
        border-radius: 3.2083px;

        svg {
          width: 17.65px;
          height: 17.65px;
        }

        &:nth-child(1) .pairs__filter-content {
          svg:first-child {
            width: 39.3px;
            height: 21.47px;
          }
        }

        &:nth-child(2) .pairs__filter-content {
          svg:first-child {
            width: 58.55px;
            height: 23.26px;
          }
        }

        &:nth-child(3) .pairs__filter-content {
          svg:first-child {
            width: 58.55px;
            height: 23.26px;
          }
        }

        &:nth-child(4) .pairs__filter-content {
          svg:first-child {
            width: 58.55px;
            height: 23.26px;
          }
        }
      }
    }

    &-label {
      &.survey {
        font-size: 16px;
        line-height: 28px;
      }
    }

    &-content {
      &.select {
        span {
          font-size: 16px;
          line-height: 19px;
          margin-top: 8.02px;
        }
      }

      &.survey {
        margin-bottom: 56px;
      }
    }

    &-subtitle {
      font-size: 18px;
      line-height: 21px;

      &.survey {
        font-size: 18px;
        line-height: 21px;
      }
    }

    &-description {
      &.survey {
        font-size: 16px;
        line-height: 24px;
        padding: 10px 14px;
        margin-top: 6px;

        &,
        &::placeholder {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  &__tab {
    &-face {
      svg {
        width: 190.28px;
        height: 265.24px;
      }
    }
  }

  &__selected {
    margin-top: 18px;

    &-title {
      margin-bottom: 6px;
    }

    &-subtitle {
      font-size: 16px;
      line-height: 18px;
    }

    &-description {
      font-size: 15px;
      line-height: 18px;
      margin-top: 20.75px;
    }
  }

  &__error {
    &.select {
      margin: 13px 0 16px 0;
    }
  }

  &__candidates {
    margin: 32px 0 46px 0;

    &-list {
      width: 472px;
      grid-template-columns: repeat(auto-fit, 152px);
      grid-column-gap: 8px;
    }

    &-content,
    &-img,
    &-block {
      border-radius: 6px;
    }

    &-content {
      grid-template-columns: repeat(2, 67px);
      grid-column-gap: 6px;
      padding: 6px;
    }

    &-block {
      border-width: 0.4px;
    }

    &-img {
      height: 85px;
    }

    &-option {
      width: 28px;
      height: 28px;
      font-size: 15px;
      line-height: 18px;
    }
  }

  .pairs__container.share-main .result {
    width: 454px;
    height: 328px;
  }

  .pairs__container.share .wrapper {
    padding-left: 55px;
  }

  .pairs__container.share-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .pairs__container.share-main .subtitle {
    font-size: 15px;
  }
}