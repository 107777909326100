.demo {
  @include setFlex(space-between, center, column);
  height: 100%;
  margin-bottom: 120px;

  & > * {
    width: 100%;
  }

  &__button {
    &.anonymize {
      @extend .sign-button;
      width: auto;
      transition: .3s linear;

      &:disabled {
        opacity: .4;
      }
    }

    &.return {
      @include setFlex(center, center, column);
      width: 34px;
      height: 34px;
      background-color: #33333C;
      border-radius: 100%;

      &.back {
        svg {
          transform: rotateX(180deg);
        }
      }
    }

    &.generate {
      @include setFlex(center, center, column);
      background-color: transparent;

      &, *, path {
        transition: .3s linear;
      }

      svg {
        margin-bottom: 5px;
      }

      span {
        @include setFont(Roboto, normal, 400, 15px, 18px);
        color: $grayColor;
      }

      &:hover {
        span {
          color: $darkYellowColor;
        }

        path {
          fill: $darkYellowColor;
        }
      }
    }

    &.select {
      @extend .sign-button;
      margin-top: 77px;
    }
  }

  &__title, &__subtitle {
    color: $lightGrayColor;
  }

  &__title {
    @include setFont(Roboto, normal, 500, 22px, 24px);
    margin: 54px 0 32px 0;
  }

  &__subtitle {
    @include setFont(Roboto, normal, 400, 20px, 24px);

    &.anonymize, &.complete {
      margin-left: 56px;
    }

    &.share {
      margin-left: 51px;
    }
  }

  &__intro {
    &.select {
      @include setFlex(flex-start, flex-start, row);
    }

    &.anonymize {
      @include setFlex(center, center, column);
      margin-top: 49px;
    }

    &.complete {
      @include setFlex(flex-start, center, row);
      width: 100%;
      margin-top: 49px;
    }
  }

  &__check {
    &-title, &-item {
      color: $grayColor;
    }

    &-title {
      @include setFont(Roboto, normal, 400, 18px, 24px);
      margin: 28px 0 13px 0;
    }
  }

  &__container {
    &.complete {
      @include setFlex(center, center, column);
    }

    &.share {
      margin-top: 49px;
    }
  }

  &__content {
    &.anonymize {
      display: grid;
      grid-template-columns: minmax(200px, 285px) 285px;
      grid-column-gap: 60px;
    }

    &.complete {
      display: grid;
      grid-template-columns: 285px 391px;
      grid-column-gap: 274px;
      justify-content: center;
      align-items: flex-start;
      margin-top: 47px;
    }
  }

  &-modal {
    &.modal > * {
      max-height: 100%;
    }

    &__button {
      &.exit {
        position: absolute;
        top: 32px;
        left: 32px;
        background-color: transparent;
      }

      &.photo {
        @include setFlex(center, center, row);
        width: 86px;
        height: 86px;
        background-color: $darkYellowColor;
        box-shadow: 4px -4px 12px rgba(0, 0, 0, 0.2);
        border-radius: 100%;
        position: relative;
        top: -34px;
      }

      &.use {
        @include setFlex(center, center, column);
        @include setFont(Roboto, normal, 500, 20px, 23px);
        width: 136px;
        height: 39px;
        color: #474747;
        background-color: $darkYellowColor;
        text-transform: capitalize;
        box-shadow: 0 10px 13px -4px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
      }

      &.retake {
        @include setFont(Roboto, normal, 400, 18px, 21px);
        color: #FFFFFF;
        background-color: transparent;

        span {
          margin-left: 6.9px;
        }
      }
    }

    &__body {
      width: 786px;
      max-width: 100%;
      min-height: 733px;
      background-color: #3B3B43;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      border-radius: 18px;
      position: relative;
    }

    &__content {
      @include setFlex(center, center, column);
      padding: 32px 0 40px 0;

      video, img {
        width: 428px;
        height: 562px;
        object-fit: cover;
        border-radius: 13.3057px;
      }

      video {
        transform: scale(-1, 1);
      }
    }

    &__title {
      @include setFont(Roboto, normal, 500, 22px, 25px);
      color: #FFFFFF;
      margin-bottom: 18px;
    }

    &__footer {
      @include setFlex(space-between, center, row);
      width: 100%;
      max-width: 428px;
      margin-top: 24px;
    }
  }

  &__photo {
    @include setFlex(center, center, row);
    position: relative;

    &-img, &-overlay {
      border-radius: 22px;
    }

    &-intro {
      @include setFlex(center, center, row);
      width: 100%;
      padding: 4px;
      background-image: linear-gradient(126.6deg, rgba(255, 255, 255, 0.096) 28.69%, rgba(255, 255, 255, 0) 100%);
      border-radius: 28px;
      border: 2px solid $grayColor;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      position: relative;
      overflow: hidden;

      &.complete {
        margin: 14px 0 44px 0;
      }

      &.error {
        border-color: #E53737;
      }
    }

    &-img {
      width: 100%;
      height: 368px;
      object-fit: cover;
      border-radius: 22px;
      background-size: cover;

      canvas {
        visibility: hidden;
        opacity: 0;
        transition: .2s linear;
      }

      &.active canvas {
        visibility: visible;
        opacity: 1;
      }
    }

    &-overlay {
      position: absolute;
      top: 3px;
      left: 3px;
      right: 3px;
      bottom: 3px;
      z-index: 2;
      background-color: rgba(#22222B, 0.7);
      visibility: hidden;
      opacity: 0;
      transition: .3s linear;

      &.active {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__error {
    @include setFont(Roboto, normal, 500, 16px, 24px);
    height: 24px;
    color: #E53737;
    margin: 40px 0 42px 0;
    visibility: hidden;
    opacity: 0;
    transition: .3s linear;

    &.active {
      visibility: visible;
      opacity: 1;
    }
  }

  &__options {
    &.anonymize {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 54px;
      align-items: center;
    }
  }

  &__label {
    &.anonymize {
      @include setFont(Roboto, normal, 400, 18px, 21px);
      color: $lightGrayColor;
      height: 91px;
      background: linear-gradient(126.6deg, rgba(255, 255, 255, 0.096) 28.69%, rgba(255, 255, 255, 0) 100%);
      border: 2px solid $lightGrayColor;
      border-radius: 8px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__face {
    &-content {
      height: 100%;
      position: relative;

      &.anonymize {
        @include setFlex(center, center, column);

        svg {
          margin-bottom: 6px;
        }
      }
    }

    &-intro {
      height: 100%;
      overflow: hidden;

      &.anonymize {
        & * {
          text-align: center;
          transition: inherit;
        }

        & > input {
          position: absolute;
          z-index: -1;
          opacity: 0;
        }

        & > span {
          display: inline-flex;
          align-items: center;
          user-select: none;
        }
      }
    }
  }

  &__original {
    &-title {
      @include setFont(Roboto, normal, 400, 20px, 23px);
      color: $grayColor;
      text-transform: capitalize;
      text-align: center;
    }

    &-icon {
      position: absolute;
      bottom: 118px;
      z-index: 1;
      visibility: hidden;
      opacity: 0;
      transition: .3s linear;

      &.active {
        visibility: visible;
        opacity: 1;
      }
    }

    &-container {
      @include setFlex(center, center, row);
      margin: 14px 0 16px 0;
    }

    &-arrow {
      background-color: transparent;

      &.next {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    &-slide {
      @include setFlex(center, center, row);
    }

    &-item {
      @include setFlex(center, center, row);
      width: 285px;
      height: 380px;
      border: 2px solid #C1C1C1;
      border-radius: 28px;
      background-color: transparent;
      padding: 4px;
    }

    &-intro, &-content, &-img {
      width: 100%;
      height: 100%;
      border-radius: 22px;
    }

    &-img {
      object-fit: cover;
    }
  }

  &__pagination {
    @include setFlex(space-between, center, row);
    padding: 0 55px;

    &-list {
      display: grid;
      grid-template-columns: repeat(3, minmax(auto, 60px));
      grid-column-gap: 2px;
    }

    &-item {
      height: 76px;
      background-color: transparent;
      border-radius: 6.03562px;
      padding: 6px;
      transition: .3s linear;

      &.active {
        background-color: #45454B;
      }
    }

    & .demo__original-img {
      border-radius: 6.03562px;
    }
  }

  &__swiper {
    &-container {
      width: 100%;
    }
  }

  &__switch {
    @include setFlex(space-between, center, row);

    &-label {
      @include setFont(Roboto, normal, 400, 18px, 21px);
      color: $lightGrayColor;
      text-align: center;
      text-transform: capitalize;
    }
  }

  &__share {
    width: 100%;
    max-width: calc(100% - 174px);
    margin-top: 60px;
  }

  @import "media_queries/index";
}

button.demo__label {
  @include setFlex(center, center, column);

  svg {
    margin-bottom: 6px;
  }

  @media(max-width: 1440px) {
    svg {
      width: 25.71px;
      height: 25.71px;
      margin-bottom: 4.86px;
    }
  }

  @media(max-width: 1025px) {
    svg {
      width: 19.26px;
      height: 15px;
      margin-bottom: 6.42px;
    }
  }

  @media(max-width: 699px) {
    svg {
      width: 28.12px;
      height: 21.9px;
      margin-bottom: 9.75px;
    }
  }
}