.share {
    &__menu {
        display: none;
        flex-direction: column;
        height: 144px;
        width: 130px;
        background-color: #45454B;
        position: absolute;
        top: -66px;
        right: 0;
        border-radius: 6px;
        box-shadow: 0px 0px 11px 7px rgba(0, 0, 0, 0.25);

        &.active {
            display: flex;
        }

        &-item {
            display: flex;
            align-items: center;
            height: 48px;
            width: 100%;
            padding-left: 13px;
            background-color: transparent;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: $lightGrayColor;
            cursor: pointer;

            &:hover {
                background-color: #8E8F9F;
            }

            & :first-child {
                width: 20px;
                margin-right: 10px;
            }

            & svg {
                height: 21px;
            }


        }
    }
}