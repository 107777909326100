@media(max-width: 699px) {
  max-width: 335px;
  padding: 0;
  margin: 50px auto auto auto;
  .mb-class {
    margin-bottom: 0;
  }

  .pl-class-list {
    padding-left: 14px;

    p {
      padding-left: 16px;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  &__date {
    font-size: 14px;
    line-height: 12px;
  }
  &__list {
    margin: 30px 0 40px 0;
    row-gap: 40px;
  }
  &__article {
    &-title {
      font-size: 18px;
      line-height: 24px;
    }

    &-subtitle {
      font-size: 16px;
      line-height: 20px;
    }

    &-description {
      font-size: 16px;
      line-height: 20px;
    }

    &-list {
      padding-left: 68px;
    }

    &-item {
      font-size: 16px;
      line-height: 20px;
    }

    &-container {
      &_title, &_item {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}