.private {
  max-width: 1540px;
  height: 100%;
  overflow: hidden;
  position: relative;

  &__button {
    &.subscribe {
      @extend .sign-button;
      margin-bottom: 20px;
    }
  }

  &__subtitle {
    &.input {
      @include setFont(Roboto, normal, 400, 20px, 24px);
      color: #DCDCDC;
      margin: 92px 0 68px 0;

      span {
        color: $darkYellowColor;
      }
    }
  }

  &__intro {
    &.input, &.feedback {
      @include setFlex(flex-start, flex-start, row);
    }

    &.feedback {
      margin-top: 132px;
    }
  }

  &__label {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 280px));
    column-gap: 52px;
    margin-bottom: 68px;

    &:nth-of-type(3), &:nth-of-type(4) {
      grid-template-columns: 1fr;
    }

    &:nth-of-type(4) {
      row-gap: 6px;
    }

    &-legend {
      @include setFont(Roboto, normal, 400, 16px, 24px);
      color: #DCDCDC;
    }
  }

  &__form {
    margin-right: 279px;
  }

  &__info {
    @include setFlex(center, flex-start, column);

    &.input {
      padding-top: 202px;
    }

    &.feedback {
      padding-top: 256px;
    }

    &-title {
      @include setFont(Roboto, normal, 500, 80px, 92px);
      color: $darkYellowColor;
    }

    &-subtitle {
      @include setFont(Roboto, normal, 500, 51px, 60px);
      color: #FFFFFF;
      margin-bottom: 8px;
    }

    svg {
      position: absolute;
      bottom: -490px;
      z-index: -1;
      margin-left: 100px;
    }
  }

  &__feedback {
    &-title {
      @include setFont(Roboto, normal, 500, 24px, 24px);
      color: #FFFFFF;
      margin-bottom: 23px;
    }

    &-subtitle {
      @include setFont(Roboto, normal, 500, 18px, 28px);
      color: $grayColor;
      max-width: 416px;
    }
  }

  @import "media_queries/index";
}