.pairs {
  width: 100%;
  margin: 54px 0 120px 0;

  &__container {

    &.select,
    &.survey {
      @include setFlex(space-between, center, column);
      max-width: 1519px;
    }

    &.share {
      @include setFlex(center, center, column);

      & .wrapper {
        width: auto;
        padding-left: 89px;
        padding-right: 100px;
        margin-top: 36px;
      }

      &-header {
        min-width: 100%;
        @include setFlex(space-between, center, row);

        & .anonymization {
          width: max-content;
          background-color: initial;
          border: 2px solid #dfb729;
          border-radius: 8px;
          color: #dcdcdc;
          font-size: 18px;
          line-height: 22px;
          margin-top: 0px;
          margin-right: 100px;
          padding: 8px 22px;
        }

        &.block {
          width: 100%;
        }

      }

      &-intro {
        display: grid;
        grid-template-columns: 1fr 1fr;
        // grid-column-gap: 56px;
        align-items: start;
      }

      &-main {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 2%;
        align-items: start;

        & .subtitle {
          margin: 46px 0 18px 0;
          font-family: Roboto, sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 26px;
          color: #ffffff;
        }

        & .share-img {
          width: 100%;
          max-width: 600px;
          border: 1px solid #767676;
          // height: 100%;
          // object-fit: cover;
        }

        & .result {
          width: 567px;
          height: 403px;
          margin-bottom: 35px;
        }
      }
    }

    &.result {
      position: relative;

      &-hover {
        width: 97%;
        height: 100%;
        background-color: #232323;
        opacity: 0.9;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        z-index: 120;

        & div {
          height: 20px;
          padding-right: 16.35px;
        }

        & svg {
          cursor: pointer;
        }

        &-icon {
          width: 100%;
          height: 100%;

          :hover {
            cursor: pointer;
          }
        }

        &-wrapper {
          visibility: hidden;
          position: absolute;
          bottom: 29px;
          width: 100%;
          height: 32px;
          padding: 0 1px 0 1px;
          display: flex;
          justify-content: center;
          align-items: center;


          &.active {
            visibility: visible;
          }
        }

        & .videoTimeline {
          background: none;
          bottom: auto;
          position: unset;

          &__buttonPlay {
            width: 12px;
            height: 16px;
          }

          &__buttonPause {
            height: 16px;
            width: 12px;
          }
        }


      }

    }

    &.wrapper {
      & .zoom__react-player {
        aspect-ratio: unset;
      }
    }
  }

  &__content {

    &.select,
    &.survey {
      width: 100%;
    }

    &.survey {
      @include setFlex(flex-start, center, column);
    }

    &.select {
      @include setFlex(flex-start, center, column);
    }
  }

  &__button {
    &.next {
      @extend .sign-button;
      @include setFont(Inter, normal, 600, 20px, 24px);

      transition: .3s linear;
      text-transform: capitalize;

      &.choose,
      &.select {
        svg {
          margin-left: 10px;
          position: relative;
          top: 1px;
        }

        &:disabled {
          opacity: .4;
        }
      }

      &.select {
        margin: 106px 0 45px 0;
      }

      &.survey {
        margin-top: 110px;
      }
    }

    &.return {
      @include setFlex(center, center, column);
      width: 34px;
      height: 34px;
      background-color: #33333C;
      border-radius: 100%;

      &.back {
        svg {
          transform: rotateX(180deg);
        }
      }
    }

    &.clear {
      @include setFont(Roboto, normal, 500, 15px, 18px);
      color: $darkYellowColor;
      background-color: transparent;
      text-transform: capitalize;
      position: absolute;
      right: 10px;
      bottom: 8px;
      transition: .3s linear;
      visibility: hidden;
      opacity: 0;

      &.active {
        visibility: visible;
        opacity: 1;
      }
    }

    &.option {
      @include setFlex(center, center, row);
      @include setFont(Inter, normal, 600, 20px, 24px);
      width: 207px;
      height: 44px;
      color: #474747;
      background-color: $darkYellowColor;
      text-transform: capitalize;
      border-radius: 8px;
    }

    &.anonymization {
      @include setFont(Inter, normal, 600, 18px, 22px);
      @include setFlex(center, center, row);
      color: #DCDCDC;
      background-color: transparent;
      text-transform: capitalize;
      padding: 8px 22px;
      border: 2px solid $darkYellowColor;
      border-radius: 8px;
      margin-top: 166px;
    }

    &.generate {
      @include setFlex(center, center, column);
      @include setFont(Roboto, normal, 400, 15px, 20px);
      width: 162px;
      height: 111px;
      color: $grayColor;
      background: linear-gradient(126.6deg, rgba(255, 255, 255, 0.096) 28.69%, rgba(255, 255, 255, 0) 100%);
      border: 0.49px solid $lightGrayColor;
      border-radius: 7px;
      margin-left: 18px;

      svg {
        width: 26px;
        height: 26px;
      }

      span {
        margin-top: 11px;
      }
    }
  }

  &__title,
  &__subtitle {
    color: $lightGrayColor;
  }

  &__title {
    @include setFont(Roboto, normal, 500, 22px, 24px);

    &.choose {
      margin-bottom: 32px;
    }

    &.anonymize {
      margin: 6px 0 28px 0;
    }
  }

  &__subtitle {
    @include setFont(Roboto, normal, 400, 20px, 24px);

    span {
      color: $darkYellowColor;
      text-transform: uppercase;
    }

    &.select,
    &.survey,
    &.share {
      margin-left: 56px;
      font-weight: 500;
    }
  }

  &__intro {
    &.choose {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 82px;
      max-width: 1075px;
    }

    &.select,
    &.survey,
    &.share {
      @include setFlex(flex-start, center, row);
    }

    &.select,
    &.share,
    &.survey {
      width: 100%;
    }

    &.anonymize {
      display: grid;
      grid-template-columns: 34px minmax(auto, 1341px);
      grid-column-gap: 56px;
      align-items: start;
    }

    &-body {
      &.anonymize {
        @include setFlex(center, center, column);
      }
    }

    &.marker-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 100%;
      max-height: 15px;
      margin: 14px 0;

      & .marker {
        width: 10px;
        height: 10px;
        margin: 0 3px;
        background-color: #FFFFFF4D;
        border: 1px solid #FFFFFF2E;
        border-radius: 5px;
      }

      & .active {
        background-color: $darkYellowColor;
      }
    }

    &-container {
      &.anonymize {
        display: grid;
        grid-template-columns: repeat(2, minmax(auto, 590px));
        grid-column-gap: 161px;

        & .pairs__select-intro {
          grid-column-gap: 20px;
        }
      }
    }
  }

  &__check {

    &-title,
    &-item {
      color: $grayColor;
    }

    &-title {
      @include setFont(Roboto, normal, 400, 20px, 24px);
      margin-bottom: 20px;
    }

    &-list {
      display: grid;
      grid-row-gap: 16px;
    }

    &-item {
      @include setFlex(flex-start, center, row);
      @include setFont(Roboto, normal, 400, 18px, 24px);

      span {
        margin-left: 15px;
      }
    }
  }

  &__select {
    @include setFlex(center, center, column);

    &-intro {
      display: grid;
      grid-template-columns: repeat(2, minmax(200px, 285px));
      grid-column-gap: 60px;

      &.demo-process {
        margin-bottom: 106px;
      }
    }
  }

  &__face {
    height: 380px;
    padding: 32px;
    background-image: linear-gradient(126.6deg, rgba(255, 255, 255, 0.096) 28.69%, rgba(255, 255, 255, 0) 100%);
    border: 2px solid $grayColor;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    position: relative;
    transition: .3s linear;

    &,
    &-input {
      border-radius: 28px;
    }

    &-intro,
    &-overlay {
      border-radius: 22.2378px;
    }

    &.error {
      border-color: #E53737;
    }

    &.selected {
      padding: 4px;
    }

    &.error {
      border-color: #FA3E3E;
    }

    &.disabled {
      background: #22222B;
      border-color: #3C3C3C;
      pointer-events: none;

      &:hover {
        cursor: default;
      }

      & .pairs__face-intro span {
        color: #3C3C3C;
      }

      path {
        stroke: #3C3C3C;
      }
    }

    &.clickable {
      &:hover {
        cursor: pointer;
      }
    }

    &-overlay {
      position: absolute;
      top: 4px;
      right: 4px;
      bottom: 4px;
      left: 4px;
      background-color: rgba(34, 34, 43, 0.7);
      transition: .1s linear;
      visibility: hidden;
      opacity: 0;

      &.active {
        visibility: visible;
        opacity: 1;
        z-index: 1;
      }
    }

    &-intro,
    &-content {
      @include setFlex(center, center, column);
    }

    &-intro {
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      border-radius: 22.2378px;
      position: relative;

      span {
        @include setFont(Roboto, normal, 400, 18px, 21px);
        color: $grayColor;
        margin-top: 17.5px;
        text-transform: capitalize;
        text-align: center;
      }
    }

    &-content {
      position: relative;
      z-index: 2;
      transition: .3s linear;

      &.active {
        position: absolute;
        left: -20px;
        bottom: -20px;
      }
    }

    &-icon {

      &,
      * {
        transition: .3s linear;
      }

      &_container,
      &_content {
        @include setFlex(center, center, row);
        background-color: transparent;
        border-radius: 100%;
      }

      &_container {
        &.active {
          width: 54px;
          height: 54px;
          padding: 2px;
          background-image: linear-gradient(101.78deg, rgba(255, 255, 255, 0.3) 1.24%, rgba(255, 255, 255, 0.18) 100%);
        }
      }

      &_content {
        width: 100%;
        height: 100%;

        &.active {
          background-color: $webBgColor;

          svg {
            width: 32.13px;
            height: 32.4px;
          }
        }
      }
    }

    &-input {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }
  }

  &__process {
    max-width: calc(100% - 188px);
    display: grid;
    grid-template-columns: minmax(350px, 483px) minmax(450px, 610px);
    column-gap: 238px;
    align-items: start;
    width: 100%;
    margin-top: 60px;

    &.survey {
      grid-template-columns: minmax(350px, 483px) minmax(450px, 610px);
    }

    &-title {
      @include setFont(Roboto, normal, 400, 20px, 23px);
      color: $grayColor;
      margin-bottom: 13px;
    }

    &-content {
      @include setFlex(center, center, column);
    }

    &-pair {
      display: grid;
      grid-template-columns: repeat(2, minmax(auto, 285px));
      grid-column-gap: 40px;
    }
  }

  &__tab {
    &-face {
      @include setFlex(center, center, row);
      height: 100%;
      border: 2px solid #6B6B6B;
      border-top: none;
      box-shadow: 0 6px 16px rgba(0, 0, 0, 0.05);
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;

      & .__react_component_tooltip {
        @include setFont(Roboto, normal, 500, 15px, 18px);
        color: #FFF;
        padding: 3px 10px;
        border-radius: 4px;

        &.show {
          opacity: 1;
        }

        &,
        & * {
          background-color: #595967;
        }
      }

      & .place-top {

        &::before,
        &::after {
          border-top-color: #595967 !important;
        }
      }
    }

    &-path {
      outline: none;
      transition: .3s linear;

      &.active {
        & {
          stroke: #00ACF6;
          stroke-width: 2px;
        }
      }

      &.hovered {
        opacity: 0.6;
      }

      &:hover {
        cursor: pointer;
      }

      &.disabled {
        fill: #6B6B6B;
        opacity: 0.2;
      }
    }

    &-subtitle {
      @include setFont(Roboto, normal, 400, 18px, 21px);
      color: $grayColor;
    }
  }

  &__filter {
    &-list {

      &.select,
      &.survey {
        display: grid;
      }

      &.select {
        grid-template-columns: repeat(2, minmax(auto, 223px));
        column-gap: 35px;
        row-gap: 30px;
        margin-top: 29px;
      }

      &.survey {
        grid-template-columns: repeat(2, 1fr);
        margin: 27px 0 16px 0;
        grid-column-gap: 133px;
        grid-row-gap: 16px;

        &.short {
          grid-template-columns: 1fr;
        }
      }
    }

    &-item {
      &.select {
        height: 160px;
        border: 1px solid #858585;
        box-shadow: 0 40px 30px -30px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        transition: .3s linear;
        position: relative;
        background-color: transparent;
        background-image: linear-gradient(126.6deg, rgba(255, 255, 255, 0.096) 28.69%, rgba(255, 255, 255, 0) 100%);

        & * {
          transition: inherit;
        }

        &>svg {
          opacity: 0;
          visibility: hidden;
          position: absolute;
          bottom: 8px;
          right: 8px;
        }

        &.active {
          border-color: $darkYellowColor;

          span {
            color: $darkYellowColor;
          }

          &>svg {
            opacity: 1;
            visibility: visible;
          }

          &>div.pairs__filter-content path {
            fill: $darkYellowColor;
          }
        }

        &.disabled,
        &:disabled {
          pointer-events: none;
          box-shadow: none;
          border-color: #474747;
          background-image: unset;

          path {
            fill: #474747;
          }

          span {
            color: #474747;
          }
        }

        &:not(.disabled),
        &:not([disabled]) {

          &:hover {
            cursor: pointer;
          }
        }
      }

      &.survey {
        @include setFlex(flex-start, center, row);
      }
    }

    &-content {
      &.select {
        @include setFlex(center, center, column);

        span {
          @include setFont(Roboto, normal, 50, 18px, 21px);
          color: $grayColor;
          text-transform: capitalize;
          margin-top: 10px;
        }
      }

      &.survey {
        @include setFlex(center, flex-start, column);
        margin-bottom: 45px;
      }
    }

    &-label {
      &.survey {
        @include setFlex(flex-start, center, row);
        @include setFont(Roboto, normal, normal, 18px, 21px);
        width: 100%;
        color: $grayColor;
        transition: .3s linear;
        text-transform: capitalize;

        & * {
          transition: inherit;
        }

        &>input {
          position: absolute;
          z-index: -1;
          opacity: 0;
        }

        &>span {
          display: inline-flex;
          align-items: center;
          user-select: none;
        }

        &>span::before {
          content: '';
          display: inline-block;
          width: 24px;
          height: 24px;
          flex-shrink: 0;
          flex-grow: 0;
          border-radius: 4px;
          margin-right: 10px;
          background-color: #3B3B43;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 50% 50%;
        }

        &>input:checked {
          &+span {
            & {
              color: $darkYellowColor;
              font-weight: 500;
            }

            &::before {
              background-image: url("../../assets/img/checkbox_checked_icon.svg");
              background-size: auto 50%;
            }
          }
        }

        &>input:not([disabled])+span::before {
          cursor: pointer;
        }

        &-container {
          width: 100%;
          position: relative;
        }
      }
    }

    &-subtitle {
      &.survey {
        @include setFont(Roboto, normal, 400, 20px, 23px);
        color: $lightGrayColor;

        span {
          color: $darkYellowColor;
          margin-left: 6px;
        }
      }
    }

    &-description {
      &.survey {
        width: 446px;
        max-width: 100%;
        min-height: 82px;
        margin-top: 8px;
        padding: 10px 13px;
        background-color: #3B3B43;

        &,
        &::placeholder {
          @include setFont(Roboto, normal, 400, 18px, 24px);
          color: #B4B4B4;
        }
      }
    }

    &-item.survey:last-child &-label {
      width: auto;
    }

    &-item.active &-content {
      path {
        fill: $darkYellowColor;
      }

      span {
        color: $darkYellowColor;
      }
    }

    &-description,
    &-comment {
      &.survey {
        border-radius: 8px;
      }
    }

    &-comment {
      &.survey {
        width: 390px;
        max-width: 100%;
        background-color: #3B3B43;
        margin-left: 14px;
        padding: 1px 10px;
        opacity: 0;
        transition: .3s linear;

        &.active {
          opacity: 1;
        }

        &,
        &::placeholder {
          @include setFont(Roboto, normal, 400, 16px, 28px);
          color: #858585;
        }
      }
    }
  }

  &__selected {
    margin-top: 24px;

    &-title {
      @include setFont(Roboto, normal, 500, 18px, 21px);
      color: $darkYellowColor;
      margin: 0 0 8px 0;
    }

    &-subtitle {
      @include setFont(Roboto, normal, 400, 18px, 24px);
      color: $lightGrayColor;
      text-transform: capitalize;
    }

    &-description {
      @include setFont(Roboto, normal, 400, 16px, 24px);
      margin-top: 26px;

      &,
      a {
        color: $grayColor;
      }

      &::before {
        content: "*";
        color: $darkYellowColor;
        margin-right: 4px;
      }
    }
  }

  &__error {
    @include setFont(Roboto, normal, 500, 16px, 24px);
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 6px;
    align-items: center;
    justify-content: center;
    min-height: 24px;
    color: #E53737;
    margin-top: 40px;
    visibility: hidden;
    opacity: 0;
    transition: .3s linear;

    &.select {
      margin: 28px 0 26px 0;
    }

    &.active {
      visibility: visible;
      opacity: 1;
    }
  }

  &__candidates {
    @include setFlex(center, center, row);
    width: 100%;
    margin: 42px 0 61px 0;

    &-list {
      width: 590px;
      display: grid;
      grid-template-columns: repeat(auto-fit, 190px);
      grid-column-gap: 10px;
      justify-content: center;
    }

    &-item {
      @include setFlex(flex-start, center, column);
      position: relative;
    }

    &-content {
      display: grid;
      grid-template-columns: repeat(2, 84px);
      grid-column-gap: 6px;
      padding: 8px;
      background-color: transparent;
      transition: .3s linear;

      &.active {
        background-color: #45454B;
      }
    }

    &-content,
    &-block,
    &-img {
      border-radius: 7px;
    }

    &-block {
      background-image: linear-gradient(126.6deg, rgba(255, 255, 255, 0.096) 28.69%, rgba(255, 255, 255, 0) 100%);
      border: 2px solid $grayColor;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      overflow: hidden;
      position: relative;
      transition: .3s linear;
      display: flex;
    }

    &-img {
      width: 100%;
      height: 107px;
      object-fit: cover;
      padding: 2px;
    }

    &-option {
      @include setFlex(center, center, row);
      @include setFont(Roboto, normal, bold, 18px, 21px);
      width: 34px;
      height: 34px;
      color: $lightGrayColor;
      background: #22222B;
      border: 1.5px solid $grayColor;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
      border-radius: 100%;
      position: absolute;
      bottom: -15px;
      transition: .3s linear;

      &.active {
        color: $darkYellowColor;
        border-width: 2px;
        border-color: #BD9D2D;
      }
    }
  }

  &__share {
    width: 100%;
    max-width: calc(100% - 186px);
    margin-top: 57px;
    display: grid;
    grid-template-columns: minmax(429.75px, 573px) auto;
    grid-column-gap: 93px;

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-content {
      @include setFlex(flex-start, flex-start, column);
    }

    &-title,
    &-subtitle {
      color: #FFFFFF;
    }

    &-title {
      @include setFont(Roboto, normal, 500, 32px, 26px);
      margin-top: 10px;
    }

    &-subtitle {
      @include setFont(Roboto, normal, 500, 20px, 26px);
      margin: 30px 0 8px 0;

      &.last {
        margin: 46px 0 18px 0;
      }
    }

    &-description {
      @include setFont(Roboto, normal, 400, 18px, 28px);
      color: $grayColor;

      a {
        @include setFont(Roboto, normal, 500, 18px, 28px);
        color: $lightGrayColor;
      }
    }

    &-list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, 52px);
      grid-column-gap: 32px;
    }

    &-item {
      @include setFlex(center, center, row);
      height: 52px;
      border: 2px solid #3B3B43;
      background-color: transparent;
      border-radius: 100%;
      transition: .3s linear;

      &:active {
        border-color: #DEB628;
      }
    }
  }

  & .video {
    &__react-player {
      height: 369px;
    }
  }

  @import "media_queries/index";
}