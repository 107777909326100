@import "@scss/mixins";
@import "@scss/colors";

.checkbox {
  &__label {
    @include setFlex(flex-start, center, row);

    &.error * {
      color: $errorColor;
    }

    & * {
      transition: .3s linear;
    }

    & > input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    & * {
      display: inline-flex;
      align-items: center;
      user-select: none;
      color: #C1C1C1;

      a {
        font-weight: 500;
        margin: 3px;
      }
    }

    &.error > span::before {
      border-color: $errorColor;
    }

    & > span::before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: 4px;
      margin-right: 8px;
      background-color: #3B3B43;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }

    & > input:checked + span::before {
      background-image: url("../../../assets/img/checkbox_checked_icon.svg");
      background-size: auto 50%;
    }

    & > input:not([disabled]) + span::before {
      cursor: pointer;
    }
  }

  &__title {
    @include setFont(Roboto, normal, normal, 15px, 24px);
    display: inline-block;
  }

  @import "media_queries/index";
}