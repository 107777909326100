.progress {
  &.modal {
    z-index: 2000;
    position: fixed;
  }

  &__body {
    display: inline-flex;
    position: relative;
  }

  &__typography {
    @include setFlex(center, center, column);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
  }
}