@media(max-width: 1440px) {

  // max-width: 1076px;
  &__title {
    font-size: 20px;
    margin: 46.38px 0 26px 0;
  }

  &__subtitle {
    font-size: 18px;
  }

  &__cards {
    margin-top: 32px;

    &-list {
      grid-row-gap: 24.32px;
    }

    &-item {
      border-radius: 8.80637px;
      border-width: 0.733864px;
    }
  }

  &__postcards {
    @include setFlex(unset, center, row);
    &-img{
      min-height:90px;
    }
    // video 
    &-video {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-left: 35px;
      max-width: 207px;
      min-width: 139px;
      width: auto;
      overflow: hidden;


      & .video {
        max-width: 158px;
        max-height: 125px;
        &__react-player{
          max-width: 135px; 
        }
        &__title{
          padding-right: 4px;
          &-text{
            // font-size: 6px;
            // font-weight: 400;
            // margin-top: 0;
            line-height: 12px;
          }

        }
      }

    }

    &-intro {
      padding: unset;
      min-width: 372.5px;
    }

    &-content {
      grid-template-columns: repeat(2, minmax(auto, 168.86px));
      grid-column-gap: 35px;
    }

    &-item {
      grid-row-gap: 6.86px;
    }

    &-block {
      height: 126.06px;
      border-width: 0.733864px;
    }

    &-subtitle {
      font-size: 14px;
    }

    &-delete {
      padding: 0 12.41px 0 13.17px;
      border-left-width: 0.733864px;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  &__original {
    grid-row-gap: 6.86px;

    &-container {
      grid-template-columns: repeat(2, minmax(auto, 79.95px));
      grid-column-gap: 2.45px;
      padding: 3.26px 3.26px 9.79px 3.26px;
    }

    &-img {
      height: 107.68px;
    }
  }

  &__details {
    max-height: 244px;
    grid-row-gap: 26.8px;
    margin-right: 22.47px;

    &-title {
      margin-bottom: 4.09px;
    }

    &-subtitle {
      text-transform: capitalize;
    }
  }

  &__share {
    &-list {
      display: grid;
      grid-template-columns: 9.79px repeat(2, 20px);
      grid-column-gap: 23px;

      button[aria-label="facebook"] {
        svg {
          width: 9.79px;
          height: 20px;
        }
      }
    }

    &-item {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__params {
    &-item {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}