.nav {
  &__intro {
    @include setFlex(flex-end, center, row);
    width: 100%;
  }

  &__button {
    &.subscribe, &.login {
      background-color: transparent;
    }

    &.subscribe {
      @include setFont(Roboto, normal, 500, 20px, 23px);
      color: $darkYellowColor;
    }

    &.login {
      @include setFont(Roboto, normal, normal, 18px, 21px);
      width: 77px;
      height: 37px;
      color: #FFFFFF;
      border: 2px solid $darkYellowColor;
      border-radius: 6px;
      margin-left: 65px;
    }
  }
  @import "media_queries/index";
}