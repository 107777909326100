// sign-UP 2560

@media(min-width: 2559px) {
    .sign-up {
        &__body {
            width: 1238px;
            height: 874px;

        }

        &__button.exit {
            width: 18px;
            height: 18px;
            top: 40px;
            left: 40px;

            & svg {
                width: 18px;
                height: 18px;
            }
        }

        &__content {
            & .sign-up__logo {
                width: 260px;
                height: 62px;
            }
        }

        &__intro {
            width: 564px;
            margin: 82px 0 55px 177px;
            padding: unset;


            & .sign-up__title.input {
                font-size: 30px;
                margin: 43px 0 43px 0;
            }

        }

        &__label {

            & .checkbox {
                &__label {
                    margin-top: 60px;
                }

                &__title {
                    font-weight: 400;
                    font-size: 21px;
                    line-height: 33px;
                }

            }

            & input {
                font-size: 22px;
                line-height: 32px;
            }
        }

        &__footer {
            margin-top: 25px;
            font-weight: 400;
            font-size: 20px;
            line-height: 33px;

            & button {
                font-size: 20px;
                line-height: 33px;
            }
        }

        & .sign-in__subtitle {
            font-size: 35px;
        }
    }

    & .sign-in {
        &__subtitle {
            &.verification {
                font-size: 21px;
                line-height: 32px;
            }
        }

        &__description {
            font-size: 21px;
            line-height: 32px;
        }

        &__button.send {
            font-size: 20px;
            line-height: 33px;
        }

        &__error {
            font-size: 20px;
            line-height: 32px;
        }
    }

}